import React from 'react';


const Index = (props)=>{
    return(
        <div className={'modal'}>
            <div className={'modal-overlay'}>
                <div className={'modal-content'} style={{width:props.innerWidth+'%'}}>
                    {props.children}
                </div>
            </div>


        </div>
    )
}


export default Index;
