import React, {useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";


const Radio = (
    {
        index,
        slug,
        handleChange,
        handleBlur,
        values,
        value,
        errors,
        touched,
        field,
        form,
        setFieldValue
    }
) => {

    const {lang, options} = useSelector(
        state => ({
            lang: state.get("lang"),

            options: state.get("options")
        }),
        shallowEqual
    );

    const dispatch = useDispatch();
    const createMarkup = (html) => {
        return {__html: html};
    };

const [val,setVal] = useState(values[field.slug]);
// console.log(values);

    return (

        <fieldset className={field.class ? field.class: '' }>
            <label dangerouslySetInnerHTML={createMarkup(field.label[lang])} />
            {field.options.map((f,inx)=>{
                if(options.debug){

                }
                return(
                    <label className="radio" key={'radio-'+field.slug+'-'+inx} style={{ border: options.debug ?  field.correct[0] == inx ? '1px solid green': 'none' : 'none'} } >
                        <input id={field.slug} name={field.slug} type="radio"  value={inx}
                               // checked={options.debug ?  field.correct[0] == inx ? true: false : false}
                               onChange={(ev)=>{
                            handleChange(ev);
                            setVal(ev.target.value);

                        }}/>
                        <p dangerouslySetInnerHTML={createMarkup(f.label[lang])} />
                    </label>
                )
            })}

            <div className={'error'}>
                {errors[field.slug] && touched[field.slug] && errors[field.slug]}

            </div>
        </fieldset>
    )
}


export default Radio
