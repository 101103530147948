import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as types from "../../actions/actionTypes";
import {UPDATE_USERDATA} from "../../actions/actionTypes";
import Modal from "../Modal/index";
import {Formik} from "formik";
import Input from "./partials/input";
import {clearCheckpoints, setFormData, setMessageData, setUserdata} from "./formActions";
import CheckBoxGroup from "./partials/checkboxGroup";
import Radio from "./partials/radio";
import TextArea from "./partials/textarea";
import SelectCountry from "./partials/selectCountry";
import Label from "./partials/label";
import checkpoint from "../CheckPoint/CheckPointReducer";

const Form = ({item, moveForward, moveForwardClick, resetChapter}) => {
    const {lang, form, options, character, checkpointStatus} = useSelector(
        state => {

            const activeForm = state.get("forms").filter(f => f.active === 1)[0];
            return {
                lang: state.get("lang"),
                form: activeForm,
                options: state.get("options"),
                character: state.get('character'),
                checkpointStatus: state.get('checkpoint')

            }
        },
        shallowEqual
    );

    const [successMsg, setSuccessMsg] = useState(null);
    const dispatch = useDispatch();
    const createMarkup = (html) => {
        return {__html: html};
    };
    let timeOut = null;
    useEffect(() => {
        console.log(2)
        return () => {
            if (timeOut) {
                clearTimeout(timeOut)
            }
        }
    })


    const [result, setResult] = useState(false);
    const getInitialValues = () => {
        let obj = {}
        form.fields.map((i, indx) => {
            if (options.debug) {
                if (i.type === 'checkboxgroup' && i.options.length > 0) {
                    i.options.map((o, iindx) => {

                        obj[i.slug + '-' + iindx] = o.correct;
                    })
                } else {
                    if (i.type !== 'label' && i.type !== 'radio') {
                        console.log(i.correct)
                        obj[i.slug] = i.correct;

                    } else if (i.type === 'radio') {
                        console.log(form.fields.filter((ff, index) => index === i.correct[0])[0]);
                        obj[i.slug] = i.correct[0];
                    }
                }
            } else {
                if (i.type === 'checkboxgroup' && i.options.length > 0) {
                    i.options.map((o, iindx) => {
                        console.log(i.slug);
                        obj[i.slug + '-' + iindx] = o.dValue;
                    })
                } else {
                    if (i.type !== 'label') {
                        obj[i.slug] = i.dValue;

                    } else if (i.type === 'radio') {
                        obj[i.slug] = '';
                    }
                }
            }

        })
        return obj
    }

    return (
        <div className={"form-container"}>
            <Modal innerWidth={form.width}>
                {form.disallowclose ?
                    <></>
                    :
                    <div
                        className={"modal-close"}
                        onClick={() => {
                            dispatch({type: types.HIDE_FORMS});
                        }}
                    >
                        X
                    </div>}

                {form && result ?
                    <div className={'cont-modal'}>
                        <div>

                            {
                                parseInt(result, 10) > form.minimumApprove ?
                                    <div className={'paragraph success'}>
                                        <div className={"cont-img"}>
                                            <div className={"img personaje"}>
                                                <img src={options.url + '/assets/img/holo.png'} className={'holo'}/>
                                            </div>
                                            <header className={'site-header'}>
                                                <div className={'title'}>
                                            {lang === 'es' ? <div><p>Respuestas al</p> <span>test</span></div> :
                                            <div><p>Test</p> <span>answer</span></div>}

                                            </div>

                                            </header>
                                            <div className={"img"}>
                                                <img src={options.url + form.success.image} alt=""/>
                                            </div>
                                            <p dangerouslySetInnerHTML={createMarkup(form.success.epigraph[lang])}
                                               className={'epigrafe'}></p>
                                        </div>
                                        <div dangerouslySetInnerHTML={createMarkup(form.success.legend[lang])}>
                                        </div>

                                        <div className={'button'} onClick={() => {

                                            moveForwardClick();
                                            dispatch({type: types.HIDE_FORMS})

                                        }}>
                                            {lang === 'es' ? "Continuar" : "Continue"}

                                        </div>

                                    </div>

                                    :
                                    <div className={'paragraph'}>

                                        <div className={"cont-img"}>
                                            <div className={"img personaje"}>
                                                <img src={options.url + '/assets/img/patri.png'} className={'patri'}/>
                                            </div>
                                            <header className={'site-header'}>
                                                <div className={'title'}>
                                            {lang === 'es' ? <div><p>Respuestas al</p> <span>test</span></div> :
                                            <div><p>Test</p> <span>answer</span></div>}

                                            </div>

                                            </header>
                                            <div className={"img"}>
                                                <img src={options.url + form.error.image} alt=""/>
                                            </div>
                                            <p dangerouslySetInnerHTML={createMarkup(form.error.epigraph[lang])}
                                               className={'epigrafe'}></p>
                                        </div>
                                        <div dangerouslySetInnerHTML={createMarkup(form.error.legend[lang])}>
                                        </div>
                                        <div className={'button'} onClick={() => {
                                            dispatch({type: types.HIDE_FORMS})
                                            resetChapter(item.previous)

                                        }}>
                                            {lang === 'es' ? "Continuar" : "Continue"}

                                        </div>
                                    </div>

                            }


                        </div>
                    </div>
                    : <></>}
                {form && result === false ? <Formik
                    initialValues={getInitialValues()}
                    validate={values => {
                        let errors = {};

                        form.fields.map((f, index) => {
                            if (f.required) {
                                if (f.slug === "email") {
                                    if (!values[f.slug]) {
                                        errors[f.slug] = lang === "es" ? "Requerido" : "Required";
                                    } else if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                            values[f.slug]
                                        )
                                    ) {
                                        errors.email =
                                            lang === "es"
                                                ? "Email invalido"
                                                : "Invalid email address";
                                    }
                                } else {
                                    if (!values[f.slug]) {
                                        errors[f.slug] = lang === "es" ? "Requerido" : "Required";
                                    }
                                }
                            }
                        });
                        return errors;
                    }}
                    onSubmit={(values, {setSubmitting}) => {


                        switch (form.type) {
                            case "form":
                                if (form.action === "userdata") {
                                    dispatch({type: UPDATE_USERDATA, payload: values});
                                    setUserdata(JSON.stringify(Object.assign({}, values, options), null, 2), moveForwardClick, dispatch)
                                        .then(() => {
                                            console.log('done')
                                            setSuccessMsg("")

                                            timeOut = setTimeout(() => {
                                                dispatch({type: types.SET_ALL_FORMS_INACTIVE});
                                                dispatch({type: types.SHOW_FORMS, payload: false});
                                                // setSuccessMsg(null)

                                                const lastZone = localStorage.getItem('zone');
                                                if (lastZone === "2") {
                                                    localStorage.removeItem('zone');
                                                    console.log('donezone')
                                                    resetChapter(1010);
                                                } else {
                                                    localStorage.removeItem('zone')
                                                    console.log('doneclick', checkpointStatus)
                                                    if(!checkpointStatus){

                                                        resetChapter(item.next);

                                                    }
                                                }
                                            }, 2000)
                                        });
                                } else if (form.action === "cierrepetrawrong") {
                                    values.textarea0 = '';
                                    let type = character === 'pascacio' ? 'unlock pascacio false' : 'unlock petra false';

                                    setMessageData(JSON.stringify(Object.assign({}, values, options, {type}), null, 2), moveForwardClick, dispatch)


                                    setSuccessMsg("")
                                    localStorage.removeItem('userdata')

                                    dispatch({type: types.SET_ALL_FORMS_INACTIVE});
                                    clearCheckpoints();
                                    timeOut = setTimeout(() => {
                                        dispatch({type: types.SEND_CLOSE_FORM, payload: values});

                                        dispatch({type: types.SHOW_FORMS, payload: false});

                                        // setSuccessMsg(null)

                                    }, 2000)


                                    resetChapter(1)

                                } else if (form.action === "cierrepetra") {
                                    console.log(values)
                                    let type = character === 'pascacio' ? 'unlock pascacio true' : 'unlock petra true';
                                    setMessageData(JSON.stringify(Object.assign({}, values, options, {type}), null, 2), moveForwardClick, dispatch);

                                    dispatch({type: types.SEND_CLOSE_FORM, payload: values});
                                    setSuccessMsg("Mensaje enviado con exito!")
                                    localStorage.removeItem('userdata')

                                    clearCheckpoints();
                                    timeOut = setTimeout(() => {
                                        dispatch({type: types.SET_ALL_FORMS_INACTIVE});
                                        dispatch({type: types.SHOW_FORMS, payload: false});
                                        // setSuccessMsg(null)

                                    }, 2000)

                                    resetChapter(1)

                                }

                                break


                            case "test":
                                let totalQuestions = 0;
                                let correctAnswers = 0;
                                let badAnswers = 0;
                                let recolect = [];
                                let isCorrect = true;
                                let selectedOption = null;
                                form.fields.map((it, inx) => {


                                    console.log("values", values);

                                    switch (it.type) {
                                        case 'checkboxgroup':
                                            console.log(typeof values)

                                            let keys = [];
                                            var output = Object.entries(values).map(([key, value]) => ({key, value}));
                                            output.map((i, index) => {
                                                if (i.value) {
                                                    keys.push(index);
                                                }
                                            })

                                            selectedOption = it.options.filter((o, index) => keys.includes(index));


                                            it.options.map((o, inx2) => {

                                                totalQuestions++;
                                                if (o.correct === values[it.slug + '-' + inx2]) {

                                                    correctAnswers++;
                                                    isCorrect = true;
                                                } else {
                                                    badAnswers++;
                                                    isCorrect = false;
                                                }
                                            })
                                            break
                                        case "radio":
                                            totalQuestions++;

                                            selectedOption = it.options[parseInt(values[it.slug], 10)];
                                            if (it.correct.includes(parseInt(values[it.slug], 10))) {

                                                correctAnswers++;
                                                isCorrect = true;
                                            } else {
                                                badAnswers++;
                                                isCorrect = false;
                                            }
                                            break


                                    }
                                    if (it.type !== "label") {

                                        recolect.push({
                                            type: it.type,
                                            question: it.label,
                                            partial: isCorrect ? "correcta" : "incorrecta",
                                            selectedOption,
                                        });
                                    }
                                });


                                // console.log(recolect)


                                let totalPoints = 100;
                                let pointsByCorrectAnswer = totalPoints / totalQuestions;
                                let pointsSubtractByWrongAnswer = (1 / (totalQuestions - 1) * 10);
                                console.log('correct', correctAnswers)
                                console.log('total', totalQuestions)
                                console.log('bad', badAnswers)
                                console.log('points+', pointsByCorrectAnswer)
                                console.log('points-', pointsSubtractByWrongAnswer);
                                console.log('res', Math.floor(pointsByCorrectAnswer * correctAnswers));
                                const res = Math.floor(pointsByCorrectAnswer * correctAnswers)

                                console.log(typeof recolect)
                                setFormData(Object.assign({}, {
                                    name: form.name,
                                    result: res.toString(),
                                    form: recolect
                                }))


                                setResult(res.toString());

                                break
                            default:
                                return;
                        }

                        timeOut = setTimeout(() => {
                            console.log(JSON.stringify(values, null, 2));
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setValues,
                          setFieldValue

                      }) => (
                        <div className={"modal-body"}>
                            <div className="title">
                                <h5 className="text-center">{form.title[lang]}</h5>
                            </div>

                            {successMsg || ''}
                            <form onSubmit={handleSubmit} style={{display: successMsg ? 'none' : 'block'}}>
                                {form.fields.map((f, index) => {
                                    switch (f.type) {
                                        case "input":
                                            return (
                                                <Input
                                                    field={f}
                                                    key={"field" + index}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    value={values[f.slug]}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            );

                                        case "textarea":
                                            return (
                                                <TextArea
                                                    field={f}
                                                    key={"field" + index}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    value={values[f.slug]}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            );

                                        case "select-country":
                                            return (
                                                <SelectCountry
                                                    field={f}
                                                    key={"field" + index}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    value={values[f.slug]}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            );

                                        case 'checkboxgroup':
                                            return (
                                                <CheckBoxGroup

                                                    field={f}
                                                    key={"field" + index}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    value={values[f.slug]}
                                                    errors={errors}
                                                    touched={touched}
                                                    form={form}
                                                    setFieldValue={setFieldValue}
                                                />
                                            );


                                        case 'radio':
                                            return (
                                                <Radio
                                                    field={f}
                                                    key={"field" + index}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    value={values[f.slug]}
                                                    errors={errors}
                                                    touched={touched}
                                                    form={form}
                                                    setFieldValue={setFieldValue}
                                                />
                                            );
                                        case 'label':
                                            return (
                                                <Label
                                                    field={f}
                                                    key={"field" + index}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    value={values[f.slug]}
                                                    errors={errors}
                                                    touched={touched}
                                                    form={form}
                                                    setFieldValue={setFieldValue}
                                                />
                                            );

                                        default:
                                            break;
                                    }
                                })}

                                <button type="submit" className={'button'} disabled={isSubmitting}>
                                    {lang === "es" ? "Enviar" : "Send"}
                                </button>
                            </form>

                        </div>
                    )}
                </Formik> : <></>}
            </Modal>
        </div>
    );
};

export default Form;
