import React, {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import {getItemsLaunched} from "./itemActions";
import {navigate} from 'hookrouter';
import * as types from '../../actions/actionTypes';
import {getVideoLaunched} from "../Video/videoActions";
import Video from "../Video/Video";
import {useSize} from 'react-hook-size';


const ItemLI = ({item}) => {
    const {options, videos} = useSelector(state => ({
        options: state.get('options'),
        videos: state.get('video'),
    }), shallowEqual);


    const dispatch = useDispatch();

    return (
        <div onClick={() => {
            navigate('chapters/' + item.id)
        }}>
            {item.name}

        </div>
    )
}


console.log("test 1");

const Items = () => {

    let itemcontainer = useRef(null)

    const {items, options} = useSelector(state => ({
        items: state.get('items'),
        options: state.get('options')
    }), shallowEqual);
    const [size, setSize] = useState(null);


   // setSize(useSize(itemcontainer));
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(size)
    })


    return (
        <div ref={itemcontainer}>

            <div>
            </div>
            <h1 onClick={() => {
              //  dispatch(getItemsLaunched(options));
                   dispatch({type:types.SHOW_FORMS,payload:1});
            }}>
                Get
            </h1>
        </div>
    )
}

export default Items
