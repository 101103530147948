import initialState from '../../redux/initialState'
import deepClone from "deep-clone";
import * as types from '../../actions/actionTypes';


const showForms = (state = initialState.showForms, action) => {
    let st = deepClone(state);
    switch (action.type) {
        case types.SHOW_FORMS:
            return action.payload;
        case types.HIDE_FORMS:
            return 0
        default:
            return state;
    }
};
export default showForms

