import React from "react";
import { shallowEqual, useSelector } from "react-redux";

const TextArea = ({
                   handleChange,
                   handleBlur,
                   values,
                   value,
                   errors,
                   touched,
                   field
               }) => {

    const { lang, options } = useSelector(
        state => ({
            lang: state.get("lang"),
            options: state.get("options")
        }),
        shallowEqual
    );

    return (
        <fieldset className={field.class ? field.class: '' }>
            <p className={field.class ? field.class + ' intro textarealabel' : 'intro textarealabel' } htmlFor={field.slug}>{field.label[lang]}</p>
            <br />
            <textarea
                type={field.type}
                name={field.slug}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
            />
            <div className={'error'}>
                {errors[field.slug] && touched[field.slug] && errors[field.slug]}

            </div>
        </fieldset>
    );
};

export default TextArea;
