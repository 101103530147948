import React, {useState} from "react";
import {Formik, Field} from "formik";
import {shallowEqual, useSelector} from "react-redux";

const CheckBox = (
    {
        index,
        slug,
        handleChange,
        handleBlur,
        values,
        value,
        errors,
        touched,
        field,
        form,
        setFieldValue
    }
) => {
    const [val, setVal]=useState(values[slug+'-'+index]);

    const {lang, options} = useSelector(
        state => ({
            lang: state.get("lang"),

            options: state.get("options")
        }),
        shallowEqual
    );
    const createMarkup = (html) => {
        return {__html: html};
    };
// console.log(values);
    return (
        <Field name={field.slug}>
            {({d, f}) => (
                <label className={'check'}>


                        <input
                            type="checkbox"
    name={slug+'-'+index}
                            checked={val}
                            onChange={(ev)=>{
                                handleChange(ev)
                                setVal(!val)
                            }}
                        />
                        <p dangerouslySetInnerHTML={createMarkup(field.label[lang])}>
                        </p>


                </label>
            )}
        </Field>

    )
}

export default CheckBox
