import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import Tags from "./Tags";
import Controls from "./Controls";
import Zones from "../Zones";
import play from '../../icons/play-solid.svg';


const Video = ({autoplay = true, controls = true, items, item = null, setReady, index = 0, moveForward, moveForwardClick, resetChapter}) => {
    const videoRef = useRef();
    const canvas = useRef();
    const subs = useRef();
    const [init, setInit] = useState(0);
    const [autoPlay, setAutoplay] = useState(autoplay);
    const [control, setControl] = useState(controls);
    const [captions, setCaptions] = useState(false);
    const [paused, setPaused] = useState(null);
    const [mute, setMute] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [oldQuality, setOldQuality] = useState(0);
    const [oldTime, setOldTime] = useState(0);
    const [quality, setQuality] = useState(0);
    const [visibility, setVisibility] = useState('hidden');
    const [display, setDisplay] = useState('none');
    const [metadata, setMetadata] = useState({videoWidth: 1, videoHeight: 1});
    const [subText, setSubText] = useState('');
    const [currenItem, setCurrentItem] = useState(item);

    const {video, options, device, lang} = useSelector(
        state => ({
            video: state.get("video"),
            options: state.get("options"),
            device: state.get("device"),
            lang: state.get("lang"),
        }),
        shallowEqual
    );

    useEffect(() => {
        if (lang === 'es') {

        } else {
            videoRef.current.textTracks[0].mode = 'showing'

            setCaptions(true);
        }
        if (device.isMobile) {
            item.videos.map((v, index) => {
                if (v.mimeType == "mp4" && v.quality == "medium") {
                    setQuality(index);

                }
            });


        }


        videoRef.current.ontimeupdate = (ev) => {
            setCurrentTime(ev.target.currentTime)
            // console.log(ev.target.currentTime);
            // console.log( metadata)
            // if(ev.target.currentTime >= metadata.duration-1 ){
            //     console.log("endedw");
            //
            //     moveForwardClick();
            //
            // }
        };

        // videoRef.current.onloadstart = ev=>{
        //     console.log("ready");
        //     if(item.poster){
        //             // var $this = this; //cache
        //             // $this.width = 1920 ;
        //             // $this.height = 1080;
        //         let ctx = canvas.current.getContext("2d");
        //         console.log(options.url + item.poster);
        //         let background = new Image();
        //         background.src = options.url + item.poster;
        //
        //         background.onload = function(){
        //             ctx.drawImage(background,0,0);
        //         }
        //     }
        // }

        videoRef.current.oncanplay = ev => {
            setReady(true);
        };
        let ctx = canvas.current.getContext('2d');
        const playEvent = videoRef.current.addEventListener('play', function () {


            var $this = this; //cache
            $this.width = videoRef.current.videoWidth;
            $this.height = videoRef.current.videoHeight;

            (function loop() {
                if (!$this.paused && !$this.ended) {
                    // ctx.drawImage($this, 1, 1);
                    ctx.drawImage($this, 0, 0, $this.width, $this.height);
                    setTimeout(loop, 1000 / 25); // drawing at 25fps
                }
            })();
        }, 0);
        const loadmedataEvent = videoRef.current.addEventListener('loadedmetadata', () => {

            setMetadata({
                videoWidth: videoRef.current.videoWidth,
                videoHeight: videoRef.current.videoHeight,
                duration: videoRef.current.duration,
                interactive: videoRef.current.dataset.interactive
            })
        });


        videoRef.current.textTracks[0].addEventListener('cuechange', function () {

            // subs.curent.innerText = this.activeCues[0].text;
            if (this.activeCues && this.activeCues.length > 0) {
                setSubText(this.activeCues[0].text);

            }
        });

        return (() => {
            videoRef.current.removeEventListener("play", () => {
                console.log('removed play event');
            })
            videoRef.current.removeEventListener("ended", () => {
                console.log('removed ended event');
            })

        })
    }, []);

    // add leaving event on item change
    useEffect(() => {

        videoRef.current.playbackRate = options.playbackrate;

    }, [item]);


    useEffect(() => {
        setPaused(videoRef.current.paused);
    });

    useEffect(() => {


        if (item.type.substr(0, 6) === 'video-' && index !== 0) {
            videoRef.current.pause();
            setVisibility('hidden');
            setDisplay('none');
        } else {

            setVisibility('visible')
            setDisplay('block');
        }

        return () => {
            // console.log('exit');
        }
    }, [item.type, index]);

    useEffect(() => {


    }, []);

    const keypressHandler = (ev) => {
        if (items.length > 0) {
            switch (ev.keyCode) {
                case 32:
                    if (!paused && items[0].id === item.id) {
                        setPaused(true)
                        pauseVideo()
                    } else if (items[0].id === item.id) {
                        setPaused(false);
                        playVideo()
                    }
                    break;
            }
        }
    }
    
    useEffect(() => {
        if (quality !== oldQuality) {
            videoRef.current.currentTime = oldTime;
            setOldQuality(quality)
        }

        return () => {
            // console.log('out');
        };
    }, [quality]);
    useEffect(() => {
        window.addEventListener("keydown", keypressHandler);
        return () => {
            window.removeEventListener("keydown", keypressHandler);
        }
    }, [paused, index]);


    const playVideo = () => {
        videoRef.current.play();
    };

    const pauseVideo = () => {
        videoRef.current.pause();
    };

    const captionsVideo = () => {
        setCaptions(!captions);
        videoRef.current.textTracks[0].mode = captions ? "hidden" : "showing";
    };

    const muteVideo = () => {
        videoRef.current.muted = !mute;
        setMute(!mute);

    }
    const qualityVideo = q => {

        setOldQuality(quality);
        setOldTime(videoRef.current.currentTime);

        if (q === "hd") {
            setQuality(0);
        } else {


            video.map((v, index) => {
                if (v.mimeType === "mp4" && v.quality === "medium") {
                    setQuality(index);
                }
            });
        }
    };


    return (
        <div className={'video-container'} data-interactive={item.interactive}>


            {paused ? (
                <div
                    className={"big-button  play-button"}
                    onClick={() => {
                        playVideo();
                    }}
                >
                    <img src={play} alt="play"/>

                </div>) : <></>}


            {item.zones.length > 0 ?
                <div className={'zones-container'}>
                    <Zones item={item} moveForward={moveForward} resetChapter={resetChapter}/>
                </div>
                : <></>}
            {captions === true ?
                <div className={'subschannel'} ref={subs}> <span>
                {subText}
            </span></div>
                : <></>}

            <canvas width={metadata.videoWidth} height={metadata.videoHeight} ref={canvas} id="canvas"
                    style={{width: '100%'}}/>

            <div className={'video-container '}
                 style={{background: options.url + item.poster, backgroundSize: 'cover'}}>

                <video
                    ref={videoRef}
                    controls={false}
                    muted={mute}
                    autoPlay={true}
                    src={item.videos[quality].url}
                    preload="metadata"
                    // poster={options.url + item.poster}
                    loop={item.loop}
                    playsInline
                    data-interactive={item.interactive}
                    onEnded={() => {
                        if (item.interactive === false) {
                            moveForwardClick();
                            console.log('interactive skip')


                        } else {
                        }
                    }}
                    onPlay={() => {
                        console.log('playing')
                        if (options.shortvideos) {
                            if (metadata.duration > 10) {
                                setTimeout(() => {
                                    videoRef.current.currentTime = metadata.duration - 3

                                }, 3000)
                            }

                        }
                    }}
                    onLoadedMetadata={(ev) => {
                        // console.log('item',{id:item.id,duration:ev.target.duration,type:item.type})
                    }}
                >
                    <track
                        label="Español"
                        kind="subtitles"
                        srcLang="es"
                        src={options.url + item.vtt}
                    />
                </video>

                <Controls
                    playVideo={playVideo}
                    pauseVideo={pauseVideo}
                    captionsVideo={captionsVideo}
                    captions={captions}
                    paused={paused}
                    qualityVideo={qualityVideo}
                    quality={quality}
                    mute={mute}
                    muteVideo={muteVideo}
                />
                <Tags currentTime={currentTime} tags={item.tags} playVideo={playVideo} pauseVideo={pauseVideo}/>
                <div className={'poster-container'} style={{
                    zIndex: -1,
                    "position": "absolute",
                    "top": "0",
                    "left": "0",
                    "width": "100%",
                    "height": "100%"
                }}>
                    <img src={item.poster} alt=""/>
                </div>

                <div>
                </div>
            </div>
            {options.debug ? <div className={'debug'}>
                    item:{item.id} <br/>
                    interactive:{item.interactive ? 'true' : 'false'} <br/>
                    next:{item.next} <br/>
                    active items : {items ? items.map((i, index) => <div key={'ite-' + index}
                                                                         className={"debug-item"}>{i.id}</div>) : 'none'}
                </div> :
                <></>}

        </div>
    );
};

export default Video;
