import initialState from '../../redux/initialState'
import * as types from '../../actions/actionTypes';
import deepClone from "deep-clone";


const choices = (state = initialState.choices, action)=> {
    let st = deepClone(state);
    switch (action.type) {
        default:
            return state;
    }
};
export default choices

