import React, {useState} from "react";
import {Formik, Field} from "formik";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import CheckBox from "./checkbox";

const CheckBoxGroup = (
    {

        handleChange,
        handleBlur,
        values,
        value,
        errors,
        touched,
        field,
        form,
        setFieldValue
    }
) => {
    const {lang, options} = useSelector(
        state => ({
            lang: state.get("lang"),

            options: state.get("options")
        }),
        shallowEqual
    );

    const dispatch = useDispatch();
    const createMarkup = (html) => {
        return {__html: html};
    };

    return (
        <fieldset className={field.class ? field.class: '' }>
            <label dangerouslySetInnerHTML={createMarkup(field.label[lang])} htmlFor="" />
<div className={'group'}>


        <div className={'item'}>


        {field.options.map((f,index)=>{
            return(
                <CheckBox
                    field={f}
                    index={index}
                    slug={field.slug}
                    key={"field" + index}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    value={values[f.slug]}
                    errors={errors}
                    touched={touched}
                    form={form}
                    setFieldValue={setFieldValue}
                />
            )
            })}
        </div>
</div>
        </fieldset>

    )
}

export default CheckBoxGroup
