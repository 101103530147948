import React, {useEffect, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as types from '../../actions/actionTypes';
import {getVideoLaunched} from "../Video/videoActions";
import Video from "../Video/Video";
import Preloader from "../Preloader";
import deepClone from "deep-clone";
import Image from "../Image";
import aspectRatio from '../../1920.png';
import Form from "../Forms/Form";
import Choice from "../Choices/Choice";
import FormInitial from "../Forms/FormInitial";
import Timeline from "../Timeline/Timeline";
import {clearCheckpoints, setCheckpoint} from "../Forms/formActions";
import lang from "../Lang/langReducer";
import {setCharacter} from "../Character/characterActions";

const Item = ({id}) => {


    const dispatch = useDispatch();

    const move = useRef(null);

    const [activeItems, setActiveItems] = useState([]);
    const [ready, setReady] = useState(false);
    const [forwardLevel, setForwardLevel] = useState(0);
    const [oldforwardLevel, setOldForwardLevel] = useState(0);


    const generateCheckpointUrl = (checkpoint) => {
        if (checkpoint) {
            let nURl = window.location.href.split("/")
            nURl.pop();
            return nURl.join("/") + "/" + checkpoint
            // return checkpoint

        }
        return false;
    }

    const {mainItem, item, items, allItems, options, videos, showForms, checkpoint, lang} = useSelector(state => ({

        mainItem: state.get('items').filter((i, index) => {
            return i.id == id
        })[0],
        item: state.get('items').filter(i => i.active === true)[0],
        items: state.get('items').filter((i, index) => {
            return i.active
        }),
        allItems: state.get('items'),
        options: state.get('options'),
        videos: state.get('video'),
        showForms: state.get('showForms'),
        lang: state.get('lang'),
        checkpoint: generateCheckpointUrl(state.get('checkpoint')),

    }), shallowEqual);


    const resetChapter = (next) => {


        console.log("next");
        console.log(next);



        dispatch({type: types.SET_INACTIVE_ALL_ITEMS});
        let nextItem = allItems.filter((i, index) => i.id === next)
        let lastSegment  = window.location.href.split("/").pop();

        if (nextItem.length !== 0) {
            // if not a choice item
            if (nextItem[0].type.substr(0, 6) !== 'choice') {

                dispatch({type: types.SET_ACTIVE_ITEMS, payload: {items: [next, nextItem[0].next]}});
            } else {
                // no need to get next for choices items
                dispatch({type: types.SET_ACTIVE_ITEMS, payload: {items: [next]}});
            }
            console.log(nextItem)
        } else {
            console.log(lastSegment);
            if (lastSegment == 1010) {
                // no need to get next for choices items
                next = 2001;
                dispatch({type: types.SET_ACTIVE_ITEMS, payload: {items: [next]}});
            }
        }
        // next = 2001;
        setOldForwardLevel(forwardLevel);
        setForwardLevel(forwardLevel + 1);

        history.pushState({}, null, '' + next);

    }

    const moveForwardClick = () => {

        move.current.click();


    }
    const moveForward = () => {

        const actualItems = deepClone(activeItems);


console.log("se rompeee");

console.log(actualItems);


        dispatch({
            type: types.SET_ACTIVE_ITEMS,
            payload: {items: [actualItems[0].next], order: actualItems[0].order + 2}
        })


        dispatch({type: types.SET_INACTIVE_ITEMS, payload: [actualItems[0].id]})

        setOldForwardLevel(forwardLevel);
        setForwardLevel(forwardLevel + 1);


        history.pushState({}, null, '' + actualItems[0].id);


    }
    useEffect(() => {

    }, [mainItem])

    useEffect(() => {

        dispatch({type: types.SET_ACTIVE_ITEMS, payload: {items: [mainItem.id, mainItem.next]}});
        return () => {
            if (mainItem['video-yt']) {
                dispatch({type: types.SET_VIDEO, payload: []});
            }
        }

    }, [])


    useEffect(() => {


        if (activeItems.length === 0 || oldforwardLevel !== forwardLevel) {
            items.map((currentItem, index) => {
                if (currentItem['video-yt']) {
                    if (currentItem.videos && currentItem.videos.length === 0) {
                        let subString = currentItem.type.substr(0, 6);
                        if (currentItem) {
                            if (subString === 'video-' || subString === 'choice') {
                                // Just for debug
                                // if (options.debug) {
                                //     console.log(item)
                                //     let it = deepClone(currentItem);
                                //     if (it.type.substr(0, 6) !== 'choice') {
                                //         const ids = ['QohH89Eu5iM'];
                                //         it["video-yt"] = ids[Math.floor(Math.random() * ids.length)];
                                //     }
                                //     dispatch(getVideoLaunched({options: options, item: it, lang: lang()}));
                                // } else {
                                //     if (options.videomode === 'offline') {
                                //         let video = [
                                //             {
                                //                 url: options.url + currentItem.src,
                                //                 quality: 'hd',
                                //                 mimeType: 'mp4',
                                //
                                //             },
                                //             {
                                //                 url: options.url + currentItem.src_low,
                                //                 quality: 'medium',
                                //                 mimeType: 'mp4',
                                //             }
                                //         ];
                                //         dispatch({
                                //             type: types.GET_VIDEO_SUCCESS,
                                //             payload: {item: currentItem, videos: video}
                                //         });
                                //     } else {
                                dispatch(getVideoLaunched({options: options, item: currentItem, lang: lang}));

                                // }
                                // }
                            }
                        }
                    }
                }
            });
            // console.log(items)

            setActiveItems(items);
            // console.log("main", mainItem)
            if (items.length > 0) {

                if (items[0].name.substr(0, 2) === 'pa') {
                    // pascasio path

                    setCharacter('pascacio', dispatch)
                } else if (items[0].name.substr(0, 2) === 'pe') {
                    // petra path

                    setCharacter('petra', dispatch)
                } else {

                }
                if (items[0].checkpoint) {
                    console.log("has-Checkpoint should save progress");
                    setCheckpoint(items[0])

                }
            }
            //normalize levels
            if (oldforwardLevel !== forwardLevel) {
                console.log("normalizing levels");
                setOldForwardLevel(forwardLevel);
            }
        }


        return () => {
            // console.log("exit");
        }
    }, [activeItems, items, forwardLevel, oldforwardLevel])


    const videoComponent = (vitem, index) => {

        return (
            <div className={'item'} id={'item-' + vitem.id} data-order={vitem.order} key={'item-' + index}
                 style={{zIndex: 99 - vitem.order, display: index !== 0 ? 'none' : 'block'}}>

                {
                    vitem.videos && vitem.videos.length > 0 ?

                        <div>
                            <Video source={vitem.videos} item={vitem} items={items} setReady={setReady} index={index}
                                   moveForward={moveForward} moveForwardClick={moveForwardClick}
                                   resetChapter={resetChapter}/>
                        </div>
                        : <></>
                }

            </div>
        )
    };


    const pictureComponent = (pitem, index) => {

        return (
            <div className={'item'} id={'item-' + pitem.id} key={'item-' + index} data-order={pitem.order}
                 style={{zIndex: 99 - pitem.order, display: index !== 0 ? 'none' : 'block'}}>


                {
                    pitem.src ?
                        <Image item={pitem} setReady={setReady} index={index} moveForward={moveForward}
                               resetChapter={resetChapter}/>

                        : <></>
                }

            </div>
        )
    };

    const choiceComponent = (citem, index) => {

        return (
            <div className={'item ' + citem.type} id={'item-' + citem.id} key={'item-' + index} data-order={citem.order}
                 style={{zIndex: 99 - citem.order, display: index !== 0 ? 'none' : 'block'}}>

                {
                    citem.videos && citem.videos.length > 0 ?

                        <div>
                            <Choice source={citem.videos} item={citem} items={items} setReady={setReady} index={index}
                                    moveForward={moveForward} moveForwardClick={moveForwardClick}
                                    resetChapter={resetChapter}/>
                        </div>
                        : <></>
                }
            </div>
        )
    };
    const formComponent = (fitem, index) => {


    };


    console.log("mostrando resepchapter");
    console.log(resetChapter);
    console.log(JSON.stringify);


    return (
        <div className={'story'}>
            {showForms ? <Form moveForward={moveForward} moveForwardClick={moveForwardClick}
                               resetChapter={resetChapter} item={items.sort((a, b) => (a.order > b.order))[0]}
                               form={showForms}/> : <></>}
            <div className="overlay">
                <i className="fas fa-undo-alt"></i>
                <p>Gira tu dispositivo</p>
            </div>
            <div id={'interface'} className={'interface content'}>

                <img src={aspectRatio} alt="aspect ratio"/>
                <div className={"move-button-2"} onClick={() => {
                    moveForwardClick();
                }}>
                    test
                </div>
                <div className={"move-button"} ref={move} onClick={() => {
                    moveForward();
                }}>move
                </div>

                <div className={"item-container"}>


                    {ready ? <></> : <Preloader ready={ready}/>}

                    {items.sort((a, b) => (a.order > b.order) ? 1 : -1).map((item, index) => {
                        if (item.type.substr(0, 6) === 'video-') {
                            return videoComponent(item, index);
                        } else if (item.type.substr(0, 6) === 'image-') {
                            return pictureComponent(item, index);
                        } else if (item.type.substr(0, 5) === 'test-') {
                            return <FormInitial key={'item-' + index} item={item} index={index}/>;
                        } else if (item.type.substr(0, 6) === 'choice') {
                            return choiceComponent(item, index);
                        }

                    })}


                </div>

                <div className={"timeline"}>
                    <Timeline items={items}/>
                </div>
                {
                    checkpoint && <div className={"checkpoint-form"}>
                        <div className="modal">
                            <div className="modal-overlay">
                                <div className="modal-content" style={{width: "30%"}}>
                                    <div className="modal-body">
                                        <div className="title"><h5 className="text-center"> Quieres empezar donde habías
                                            quedado la última vez?</h5></div>

                                        <div style={{display: "flex"}}>

                                            <a href={checkpoint} className="button">SI</a>
                                            <button className={"button"} onClick={() => {
                                                dispatch({type: types.ASK_FOR_CHECKPOINT, payload: false});
                                                dispatch({type: types.SET_ALL_FORMS_INACTIVE});
                                                dispatch({type: types.SHOW_FORMS, payload: false});
                                                clearCheckpoints()

                                                moveForwardClick();
                                                setTimeout(() => {

                                                    moveForwardClick();
                                                }, 2000)

                                            }}>NO
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                }
            </div>

        </div>

    )
}

export default Item
