import {call, put,all,takeLatest} from "@redux-saga/core/effects";
import {getItems,getItemsSuccess} from './itemActions';
import * as types from '../../actions/actionTypes';



function* fetchItems(action){


    try{
        const resp = yield call(getItems,action);
        yield put(getItemsSuccess(resp.data))
    }
    catch(e){

    }
}



export default function* itemsSaga() {
    yield all([
        takeLatest(types.GET_ITEMS_LAUNCHED, fetchItems)
    ]);
}
