import React, {useRef} from 'react';
import {useSize} from "react-hook-size";
import {shallowEqual, useDispatch, useSelector} from "react-redux";



const Caption = ({caption, height, width}) => {




    const captionStyle = {
        position: 'absolute',
        top: (height / 100 * caption.top) + 'px',
        left: (width / 100 * caption.left) + 'px',
        width: caption.width,
        height: caption.height,
        color:"#fff",

    };
    return (<div style={captionStyle}>
        {caption.text}
    </div>)
}

const Captions = ({captions}) => {
    let ref = useRef();
    let {width, height} = useSize(ref);
    const {options, device, lang} = useSelector(
        state => ({
            options: state.get("options"),
            device: state.get("device"),
            lang: state.get("lang"),
        }),
        shallowEqual
    );
    // console.log(lang);

    if (lang === "es") {
        return (<div></div>)
    } else {
        return (

            <div ref={ref} className={"floating-captions-container"}
                 style={{"position": "absolute", "top": "0", "left": "0", "height": "100%", "width": "100%"}}>
                {captions.map((c, index) => {
                    return (
                        <Caption key={"caption-" + c.id} caption={c} width={width} height={height}/>
                    )
                })}

            </div>
        )
    }


};


export default Captions
