import initialState from '../../redux/initialState'
import * as types from '../../actions/actionTypes';
import deepClone from "deep-clone";


const device = (state = initialState.device, action)=> {
    let st = deepClone(state);
    switch (action.type) {
        case types.UPDATE_DEVICE_DATA:
            return action.payload;
        default:
            return state;
    }
};
export default device

