import initialState from '../../redux/initialState'
import deepClone from "deep-clone";
import * as types from '../../actions/actionTypes';


const forms = (state = initialState.forms, action) => {
    let st = deepClone(state);
    switch (action.type) {

        case types.SET_ACTIVE_FORM:
             return  st.map((item,index)=>{
                // console.log(item)
                if(item.id === action.payload){
                    item.active=1;
                }else {
                    item.active=false;
                }
                return item;
            })
        case types.SET_ALL_FORMS_INACTIVE:
            return st.map((item,index)=>{
                if(item.id === action.payload){
                    item.active=false;
                }
                return item;
            })
        default:
            return state;
    }
};
export default forms

