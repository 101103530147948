export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_LAUNCHED = "GET_ITEMS_LAUNCHED";

export const SET_ACTIVE_ITEMS = "SET_ACTIVE_ITEMS";
export const SET_INACTIVE_ITEMS = "SET_INACTIVE_ITEMS";
export const SET_ACTIVE_INACTIVE_ITEMS = "SET_ACTIVE_INACTIVE_ITEMS";
export const SET_INACTIVE_ALL_ITEMS = "SET_INACTIVE_ALL_ITEMS";
export const SHOW_FORMS = "SHOW_FORMS";
export const HIDE_FORMS = "HIDE_FORMS";
export const SET_ACTIVE_FORM = "SET_ACTIVE_FORM";
export const SET_ALL_FORMS_INACTIVE = "SET_ALL_FORMS_INACTIVE";

export const SET_OPTIONS = "SET_OPTIONS";
export const GET_VIDEO_LAUNCHED = "GET_VIDEO_LAUNCHED";
export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS";
export const SET_VIDEO = "SET_VIDEO";

export const SET_LANG = "SET_LANG";
export const TOGGLE_LANG = "TOGGLE_LANG";

export const UPDATE_USERDATA = "UPDATE_USERDATA";
export const SEND_CLOSE_FORM = "SEND_CLOSE_FORM";
export const UPDATE_DEVICE_DATA = "UPDATE_DEVICE_DATA";

export const SET_VIDEO_MODE = "SET_VIDEO_MODE"
export const ASK_FOR_CHECKPOINT = "ASK_FOR_CHECKPOINT"


export const SET_CHARACTER = "SET_CHARACTER";
