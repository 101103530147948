import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getVideoLaunched} from "../Video/videoActions";
import * as types from "../../actions/actionTypes";
import useInterval from '@use-it/interval';

import Hammer from "react-hammerjs";
import lang from "../Lang/langReducer";
// Custom options
const touchoptions = {
    touchAction: 'compute',
    recognizers: {
        tap: {
            time: 600,
            threshold: 100
        }
    }
};


const debounce = (fn, interval) => {
    let timer;
    return function debounced() {
        clearTimeout(timer);
        let args = arguments;
        let that = this;
        timer = setTimeout(function callOriginalFn() {
            fn.apply(that, args);
        }, interval);
    };
}

const videosceneStyle =
    {
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "paddingBottom": "56.25%",
        "paddingTop": "0px",
        "height": "0"
    }
const videoStyle =
    {"position": "absolute", "top": "0", "left": "0", "width": "100%", "height": "100%"}

const Choice = ({autoplay = false, controls = true, item = null, setReady, index = 0, moveForward, moveForwardClick, resetChapter}) => {

    const boxRef = useRef(null);
    const choiceRef = useRef(null);
    const canvas = useRef();


    const {video, options, choice, device, lang} = useSelector((state) => ({
        video: state.get('video'),
        options: state.get("options"),
        device: state.get("device"),
        lang: state.get("lang")


    }), shallowEqual);
    const start_forward = 3.0;
    const end_forward = 10.00;
    const start_backwards = 11.0;
    const end_backwards = 19.0;
    const left_from = 22.00;
    const left_to = 25;
    const right_from = 27;
    const right_to = 29;
    const constants = {
        RIGHT: "RIGHT",
        LEFT: "LEFT",
        FACE_ANIMATION_START_TIME: start_forward,
        FACE_ANIMATION_TOTAL_TIME: (end_backwards - start_forward),
        FACE_ANIMATION_SEGMENT_LENGTH: end_backwards / 2,
        FACE_ANIMATION_HALF_SEGMENT_LENGTH: (end_backwards / 2) / 2,
    };
    //hooks
    const [percent, setPercent] = useState(0);
    const [xPos, setXPos] = useState(0);
    const [direction, setDirection] = useState(null);
    const [init, setInit] = useState(0);
    const [currentTime, setCurrentTime] = useState(end_forward / 2)
    const [toTime, setToTime] = useState(15);
    const [playbackRate, setPlaybackRate] = useState(1.0);
    const [timecode, setTimeCode] = useState(0);
    const [toggle, setToggle] = useState(1);
    const [leaving, setLeaving] = useState(false);
    const [isTouching, setIsTouching] = useState(false);
    const [clicked, setClicked] = useState(false);


    const dispatch = useDispatch();

    useInterval(() => {

        setTimeCode(timecode => timecode + 1);
    }, 1000)


    const move = (ev, touch = false) => {

        let clientX = null;
        // ev.persist();
        if (touch) {
            clientX = ev.center.x;
            setIsTouching(1);
        } else {
            clientX = ev.clientX;
        }
        const boxWidth = boxRef.current.getBoundingClientRect().width;

        if (clientX > xPos) {
            setDirection(constants.RIGHT)

        } else {
            setDirection(constants.LEFT);
        }

        setXPos(clientX);
        const percent = (xPos / boxWidth) * 100;
        //    console.log(percent);
        if (0 <= percent && percent <= 100) {
            setPercent(percent);
            if (percent >= 50) {

                if (toggle === 3 || toggle === 1) {


                    if (direction) {
                        choiceRef.current.currentTime = start_forward;

                    } else {
                        choiceRef.current.currentTime = end_forward / 2;
                    }


                    setDirection(constants.RIGHT);
                    setToTime(end_forward);


                    setToggle(2);

                }

            } else {

                if (toggle === 2 || toggle === 1) {


                    if (direction) {
                        choiceRef.current.currentTime = start_backwards;

                    } else {
                        choiceRef.current.currentTime = end_forward / 2;
                    }


                    setDirection(constants.LEFT);
                    setToTime(end_backwards);
                    // choiceRef.current.play();

                    setToggle(3);

                }
            }


        }
    };

    const processclick = () => {
        choiceRef.current.playbackRate = 1;
        setClicked(true);
        if (percent > 50) {

            setToTime(right_to);
            setDirection(constants.RIGHT)
            choiceRef.current.currentTime = right_from;
            setLeaving(item.choices.right);
localStorage.setItem('last-choice', "right");
        } else {
            choiceRef.current.currentTime = left_from;
            setDirection(constants.LEFT)
            setToTime(left_to);

            setLeaving(item.choices.left);
            localStorage.setItem("last-choice","left")
        }
    }


    useEffect(() => {
        // choiceRef.current.playbackRate = 15;
        if (choice) {
            dispatch(getVideoLaunched({options: options, item: choice, lang: lang()}));
        }
        return () => {
            dispatch({type: types.SET_VIDEO, payload: []});
        }

    }, [])

    useEffect(() => {
        let timeout = null;
        if (leaving !== false) {
            const time = direction === constants.LEFT ? (left_to - left_from + 1) * 1000 : (right_to - right_from + 1) * 1000
            timeout = setTimeout(() => {
                resetChapter(leaving);
            }, time)
        }

        return () => {
            console.log('exited');
            //
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, [leaving])

    useEffect(() => {


        choiceRef.current.ontimeupdate = (ev) => {
            if (leaving !== false) {
            }
            if (direction) {
                if (ev.target.currentTime >= toTime) {
                    choiceRef.current.pause();
                    if (device.isMobile) {
                        if (isTouching === 1) {
                            processclick();
                            setIsTouching(2);
                        }
                    }
                } else {
                    choiceRef.current.play();
                }
            }

            let ctx = canvas.current.getContext('2d');
            if (choiceRef.current.videoWidth && choiceRef.current.videoHeight) {
                choiceRef.current.width = choiceRef.current.videoWidth;
                choiceRef.current.height = choiceRef.current.videoHeight;
                (function loop() {
                    if (choiceRef.current) {
                        ctx.drawImage(choiceRef.current, 0, 0, choiceRef.current.width, choiceRef.current.height);
                        setTimeout(loop, 1000 / 25); // drawing at 30fps
                    }
                })();

            }

        };
        if (init === 0) {
            if (choiceRef.current.seekable.length > 0) {
                choiceRef.current.currentTime = end_forward / 2;
                setInit(1);
            }
        }

        return () => {
            // TODO: desmontar eventos

            //   console.log('exited');

        }
    })
    const handleTap = (ev) => {
    }

    return (
        <Hammer onTap={(ev) => {
            move(ev, true)
        }} options={touchoptions}>
            <div>


                <div className="videoscene" style={videosceneStyle}>
                    <canvas width={1280} height={720} ref={canvas} id="canvas" style={{width: '100%'}}></canvas>
                    <img className={'image-choice'} src={item.poster} alt=""/>
                    <video ref={choiceRef} autoPlay={false} muted={true} controls={true} style={videoStyle}
                           src={item.videos[0].url} controls={false}
                           playsInline preload="metadata"
                           poster={""}
                           onLoadedMetadata={(ev) => {ev.target.playbackRate = 15;}}
                    />
                </div>

                {index === 0 && clicked === false ?
                    <div
                        className="box"
                        ref={boxRef}
                        onMouseMove={(ev) => {
                            debounce(move(ev), 1000)
                        }}
                        onMouseLeave={() => {
                            if (percent > 50) {
                                setPercent(100);
                            } else {
                                setPercent(0);
                            }
                        }}
                        onClick={(ev) => {
                            if (device.isBrowser) {
                                processclick(ev);
                            }
                        }}
                    >
                        <span style={{height: "99%", width: percent + "%"}}/>
                    </div>
                    : <></>}

            </div>
        </Hammer>
    )
}

export default Choice
