import { combineReducers } from 'redux-immutable';
import items from '../components/Items/itemReducer';
import forms from "../components/Forms/formsReducer";
import showForms from "../components/Forms/showFormsReducer";
import options from "../components/Options/optionsReducer";
import video from "../components/Video/videoReducer";
import choices from "../components/Choices/choiceReducer";
import lang from "../components/Lang/langReducer";
import userdata from "../components/Forms/userDataReducer";
import device from "../components/Device/deviceReducer";
import checkpoint from "../components/CheckPoint/CheckPointReducer";
import character from '../components/Character/characterReducer';
const  rootReducer= combineReducers({
    items,
    showForms,
    options,
    video,
    choices,
    forms,
    lang,
    userdata,
    device,
    checkpoint,
    character
});

export default rootReducer;
