import initialState from '../../redux/initialState'
import * as types from '../../actions/actionTypes';
import deepClone from "deep-clone";


const items = (state = initialState.items, action) => {
    let st = deepClone(state);
    switch (action.type) {

        case  types.SET_INACTIVE_ALL_ITEMS:
            st = state.map((item, index) => {
                item.active = false;
                item.order= 1;
                return item;
            });
            return st;
        case types.GET_VIDEO_SUCCESS:
            const it = state.map((item, index) => {
                if (item.id === action.payload.item.id) {
                    item.videos = action.payload.videos;
                }
                return item
            })
            return it;
        case types.SET_ACTIVE_ITEMS:
            st = state.map((item, index) => {
                action.payload.items.map((id, ind) => {
                    if (item.id === parseInt(id, 10)) {
                        item.active = true;
                        if(action.payload.order){
                            item.order =action.payload.order;
                        }else{
                            item.order =ind;
                        }

                    }
                    return null
                });
                if(item.active){

                    // console.log("ID", item.id)
                }
                return item;
            });
            return st;
        case types.SET_ACTIVE_INACTIVE_ITEMS:
            st = state.map((item, index) => {
                action.payload.items.map((id, ind) => {
                    if (item.id === parseInt(id, 10)) {
                        item.active = true;
                        if(action.payload.order){
                            item.order =action.payload.order;
                        }else{
                            item.order =ind;
                        }

                    }else{
                        item.active = false;
                            item.order = 1;
                    }
                    return null
                });
                return item;
            });
            return st;
        case types.SET_INACTIVE_ITEMS:
            st = state.map((item, index) => {
                action.payload.map((id, ind) => {
                    if (item.id === parseInt(id, 10)) {
                        item.active = false;
                        item.order= 1;
                    }
                    return null
                });
                return item;
            });
            return st;
        case types.GET_ITEMS_SUCCESS:
            st = action.payload
            return st
        default:
            return state;
    }
};
export default items

