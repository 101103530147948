import axios from 'axios';
import * as types from '../../actions/actionTypes';


export const getVideoSuccess = (payload) => {

    return {
        type: types.GET_VIDEO_SUCCESS, payload: payload
    }
};

export const getVideoError = ()=>{
    // console.log('errr')
    return {
        type: types.SET_VIDEO_MODE, payload: 'offline'
    }
}

export const getVideoLaunched = (options) => ({
    type: types.GET_VIDEO_LAUNCHED,
    payload: options
});

export const getVideo = (action) => {
    // console.log("ACTION",action)

   let v =  action.payload.item['video-yt']
    if(action.payload.lang=== 'en' && action.payload.item['video-yt-en']){
        v = action.payload.item['video-yt-en'];
    }

    return axios.get(action.payload.options.url + '/api/video?v=' + v).then((r) => {

        return {
            item:action.payload.item,
            videos:r.data,
        }
    })
};


