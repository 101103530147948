import initialState from '../../redux/initialState'
import * as types from '../../actions/actionTypes';
import deepClone from "deep-clone";


const character = (state = initialState.character, action)=> {
    let st = deepClone(state);
    switch (action.type) {
        case types.SET_CHARACTER:
            return action.payload;
        default:
            return state;
    }
};
export default character

