import initialState from '../../redux/initialState'
import * as types from '../../actions/actionTypes';
import deepClone from "deep-clone";


const lang = (state = initialState.lang, action) => {
    let st = deepClone(state);
    switch (action.type) {
        case types.SET_LANG :
            st = action.payload
            return st;
        case types.TOGGLE_LANG:
            let lang = ''
            if (state === 'es') {
                lang = 'en';
            } else {
                lang = 'es';
            }
            st = lang
            return st
        default:
            return state;
    }
};
export default lang

