import React, {useRef} from 'react';
import {useSize} from 'react-hook-size';
import {navigate} from 'hookrouter';

const Zones = ({item, moveForward,resetChapter}) => {

    let ref = useRef();
    let {width, height} = useSize(ref);


    const clickHandler = (zone) => {
            if(item.id === 4){

                localStorage.setItem('zone',zone.id)
            }
        if (zone.type === 'continue') {
            moveForward()
        }else if(zone.type === 'redirect'){
            navigate(zone.next)
          resetChapter(zone.next);
        }

    }
    return (
        <div className={"zone-container"} ref={ref}>


            {item.zones.map((z, index) => {
                const zoneStyle= {

                    position: "absolute",
                        top: (height / 100 * z.top) + 'px',
                    left: (width / 100 * z.left) + 'px',
                    height: (width / 100 * z.height) + 'px',
                    width: (width / 100 * z.width) + 'px',

                }

                const mergedStyle = Object.assign({},zoneStyle,z.style);

                return (
                    <div key={'zone-' + index} className={'zone'} style={mergedStyle} onClick={(ev) => {
                        clickHandler(z);
                    }}>
                        {/*{width} {height}*/}
                    </div>
                )

            })}
        </div>

    )
}

export default Zones;
