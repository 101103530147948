import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const Label = ({
                   handleChange,
                   handleBlur,
                   values,
                   value,
                   errors,
                   touched,
                   field
               }) => {

    const { lang, options } = useSelector(
        state => ({
            lang: state.get("lang"),
            options: state.get("options")
        }),
        shallowEqual
    );

    const createMarkup = (html) => {
        return {__html: html};
    };

    return (
     <p className={field.class ? field.class + ' intro' : 'intro' } dangerouslySetInnerHTML={createMarkup(field.label[lang])}>

     </p>
    );
};

export default Label;
