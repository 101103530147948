import initialState from '../../redux/initialState'
import * as types from '../../actions/actionTypes';
import deepClone from "deep-clone";


const options = (state = initialState.options, action) => {
    let st = deepClone(state);
    switch (action.type) {

        case types.SET_VIDEO_MODE:
            st.videomode = action.payload;
            return st

        case types.SET_OPTIONS:
            st = action.payload
            return st

        default:
            return state;
    }
};
export default options

