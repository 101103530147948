import initialState from '../../redux/initialState'
import deepClone from "deep-clone";
import * as types from '../../actions/actionTypes';


const userdata = (state = initialState.userdata, action) => {
    let st = deepClone(state);
    switch (action.type) {
        case types.UPDATE_USERDATA:
            st = action.payload;
            return  st;
        default:
            return state;
    }
};
export default userdata

