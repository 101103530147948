import React from 'react';


import Home from './components/Pages/Home';
import About from "./components/Pages/About";
import Item from "./components/Items/Item";
import Choice from "./components/Choices/Choice";

const routes = {
    '/': () => <Home/>,
    "/about": () => <About />,
    "/chapters/:id":({id}) =><Item id={id} />,
    "/choice/:id":({id})=><Choice id={id} />
};


export default routes;
