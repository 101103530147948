import React from 'react';

import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as types from './actions/actionTypes'
import {setBasepath, useRoutes} from 'hookrouter';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
} from "react-device-detect";



import routes from './routes';
import {getUserData} from "./components/Forms/formActions";

function App(props) {

    setBasepath(props.options.basePath);
    const routeResult = useRoutes(routes);
    const {showForms} = useSelector(state => ({
        showForms: state.get('showForms'),
    }), shallowEqual);

    const dispatch = useDispatch();


    const {lang, options,userdata,device} = useSelector(state => ({
        lang: state.get('lang'),
        options: state.get('options'),
        userdata: state.get('userdata'),
        device:state.get('device'),
    }), shallowEqual);

    if(Object.keys(options).length === 0) {
        dispatch({type: types.SET_OPTIONS, payload: props.options});
    }
    if(!lang){
        dispatch({type: types.SET_LANG, payload: props.options.initialLang});
    }

    const udata =getUserData();
    if(Object.keys(userdata).length === 0 && udata){
        dispatch({type:types.UPDATE_USERDATA, payload: udata})
    }
    if(Object.keys(device).length === 0 ){
    console.log();
        dispatch({type:types.UPDATE_DEVICE_DATA, payload: deviceDetect()})

    }



    return routeResult

}

export default App;
