import React, {useEffect, useState, useRef} from "react";
import Link from "./Link";
import {useSize} from 'react-hook-size';
import ReactModal from 'react-modal';
import {shallowEqual, useSelector} from "react-redux";


const Tags = ({tags, currentTime, playVideo, pauseVideo}) => {
    const [activeTags, setActiveTags] = useState([]);
    const [modalContent, setModalContent] = useState({});
    let ref = useRef();
    let {width, height} = useSize(ref);

    const [showModal, setShowModal] = useState(false);


    const {lang,options, device} = useSelector(
        state => ({
            lang:state.get('lang'),
            options: state.get("options"),
            device: state.get("device"),
        }),
        shallowEqual
    );
    useEffect(() => {
        setActiveTags(() => {
            return tags.filter((t, index) => {
                return (
                    t.in <= Math.floor(currentTime) && t.out >= Math.floor(currentTime)
                );
            });
        });
    });


    const handleOpenModal = (tag) => {

        setModalContent(tag);
        setShowModal(true)
        pauseVideo();
    }

    const handleCloseModal = () => {
        setShowModal(false)
        playVideo();


    }

    return (
        <div>


            <div>
                <ReactModal
                    isOpen={showModal}
                    contentLabel=""
                >
                    <button onClick={handleCloseModal}>X</button>


                        { modalContent.type  === 'image' ?
                                <img src={options.url+modalContent[lang==="es" ? "link" : "link_en"] }alt=""/>
                                :
                                <></>

                        }

                    { modalContent.type  === 'video' ?
                        <iframe width="560" height="315" src={"https://www.youtube.com/embed/"+modalContent.link+"?controls=0"}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                        :
                        <></>

                    }





                </ReactModal>
            </div>

            <div ref={ref} className={"tags-container"}>

                {activeTags.map((t, index) => {
                    return <Link key={"tag-" + index} width={width} height={height} tag={t} handleOpenModal={handleOpenModal} handleCloseModal={handleCloseModal} />;
                })}
            </div>
        </div>
    );
};

export default Tags;
