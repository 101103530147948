import React from 'react';


const About = ()=>{
    return (
        <div>
            About
        </div>
    )
}


export default About
