import axios from 'axios';
import * as types from "../../actions/actionTypes";

export const setCheckpoint = (item) => {
    const userData = localStorage.getItem("userdata");

    axios.post(process.env.MIX_REACT_BASE_URL + 'api/checkpoint', {
        params: {userData},
        checkpoint: item,
    })

}
export const clearCheckpoints = () => {
    const userData = localStorage.getItem("userdata");

    axios.post(process.env.MIX_REACT_BASE_URL + 'api/checkpoint-clear', {
        params: {userData},
    })
}

export const getCheckpoint = () => {
    const userData = localStorage.getItem("userdata");
    if(!userData ) return Promise.resolve();
    return axios.post(process.env.MIX_REACT_BASE_URL + 'api/get-checkpoint', {
        params: {userData},
    }).then((response) => {
        return response;
    }).catch((error) => {
        console.error("Error: Can't get checkpoint from user")
        if (userData === null) {

            console.error("Error: userdata not available")
        }
    })
}

export const setMessageData = (messageData, moveForwardClick, dispatch) => {
    console.log(messageData)
    const userData = localStorage.getItem("userdata");
    axios.post(process.env.MIX_REACT_BASE_URL + "api/message", {
        params: {
            userData,
            messageData,
        }
    }).then((response) => {
        console.log(response)
    }).catch((err) => {
        console.error("error", err)
    }).finally(() => {
        console.log("done messageData")
    })


}
export const setFormData = (testdata) => {


    const userData = localStorage.getItem("userdata");
    const testData = JSON.stringify(testdata);

    console.log(process.env)

    axios.post(process.env.MIX_REACT_BASE_URL + 'api/testdata', {
            params: {
                userData,
                testData
            },
        }
    )
        .then(function (response) {
            // handle success
            console.log(response);

        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .finally(function () {
            // always executed
        });

}


export const setUserdata = (userdata, moveForwardClick, dispatch) => {
    // debugger;
    return new Promise((resolve, reject) => {

        const data = JSON.parse(userdata);
        const isUserdata = localStorage.getItem("userdata");
        // if userdata doesn't  exist or is a different user else get checkpoints
        console.log(isUserdata)
        if (!isUserdata || JSON.parse(isUserdata).email !== data.email) {


            axios.post(process.env.MIX_REACT_BASE_URL + 'api/userdata', {
                    params: userdata,
                }
            )
                .then(function (response) {
                    // handle success
                    localStorage.setItem('userdata', userdata)
                    resolve(true)
                })
                .catch(function (error) {
                    // handle error

                    console.log(error);
                    reject(error)
                })
        } else {
            getCheckpoint().then((res) => {
                dispatch({type: types.ASK_FOR_CHECKPOINT, payload:res.data.checkpoint})
                resolve(true);
            })
        }
    })
}

export const getUserData = () => {
    return localStorage.getItem('userdata');
}

export const sendRouteComplete = (values) => {
    axios.post(process.env.MIX_REACT_BASE_URL + 'api/unblock', {
            params: {
                values
            },
        }
    )
        .then(function (response) {
            // handle success
            console.log(response);

        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .finally(function () {
            // always executed
        });


}
