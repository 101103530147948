import React, {useState} from "react";
import captionsRegular from '../../../icons/closed-captioning-regular.svg';
import captionsSolid from '../../../icons/closed-captioning-solid.svg';
import muteSolid from '../../../icons/volume-mute-solid.svg';
import muteUp from '../../../icons/volume-up-solid.svg';
import play from '../../../icons/play-solid.svg';
import pause from '../../../icons/pause-solid.svg';
import fullscreen from '../../../icons/fullscreen.svg';

const Controls = ({
                      playVideo,
                      pauseVideo,
                      captionsVideo,
                      captions,
                      paused,
                      quality,
                      qualityVideo,
                      mute,
                      muteVideo
                  }) => {
    const [qualityModal, setQualityModal] = useState(false);
    const [fullscreenState, setFullscreenState] = useState(false);
    const fullScreenMode = true;
    const openFullscreen = () => {
        if (fullScreenMode) {
            try {


                var elem = document.documentElement;

                if (elem.requestFullscreen) {
                    elem.requestFullscreen().then(r => {
                        console.log(r)
                    });
                    // @ts-ignore
                } else if (elem.mozRequestFullScreen) { /* Firefox */
                    // @ts-ignore
                    elem.mozRequestFullScreen();
                    // @ts-ignore
                } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                    // @ts-ignore
                    elem.webkitRequestFullscreen();
                    // @ts-ignore
                } else if (elem.msRequestFullscreen) { /* IE/Edge */
                    // @ts-ignore
                    elem.msRequestFullscreen();
                }
            } catch (e) {
                console.log(e)
            }
        }
    }
    const closeFullscreen = () => {
        if (fullScreenMode) {
            var elem = document.documentElement;

            if (document.exitFullscreen) {
                document.exitFullscreen();
                // @ts-ignore
            } else if (document.mozCancelFullScreen) {
                // @ts-ignore
                document.mozCancelFullScreen();
                // @ts-ignore
            } else if (document.webkitExitFullscreen) {
                // @ts-ignore
                document.webkitExitFullscreen();
                // @ts-ignore
            } else if (document.msExitFullscreen) {
                // @ts-ignore
                document.msExitFullscreen();
            }
        }
    }
    return (
        <div className={"controls"}>
            <div className={'control-buttons'}>
                {qualityModal ? (
                    <div className={"control-button quality-modal"}>
                        {quality == 0 ?
                            <div onClick={() => {
                                qualityVideo("sd");
                            }}>
                                HD
                            </div> :
                            <div onClick={() => {
                                qualityVideo("hd");
                            }}>
                                SD
                            </div>}
                    </div>
                ) : (
                    <></>
                )}
                {paused ? (
                    <div
                        className={"control-button  play-button"}
                        onClick={() => {
                            playVideo();
                        }}
                    >
                        <img src={play} alt="play"/>

                    </div>
                ) : (
                    <div className={"control-button pause-button"}
                         onClick={() => {
                             pauseVideo();
                         }}>
                        <img src={pause} alt="play"/>

                    </div>
                )}

                {/*<div className={"control-button quality-button"}*/}
                {/*     onClick={() => {*/}
                {/*         setQualityModal(!qualityModal);*/}
                {/*     }}>*/}
                {/*    quality*/}
                {/*</div>*/}
                <div className={"controle-button fullscreen-button"} onClick={() => {
                    if (fullscreenState) {
                        closeFullscreen();

                    } else {
                        openFullscreen()
                    }
                    setFullscreenState(!fullscreenState);
                }}>
                    <img src={fullscreen} alt="fullscreen"/>

                </div>
                <div className={"control-button mute-button"}
                     onClick={() => {
                         muteVideo();
                     }}>
                    <img src={mute ? muteSolid : muteUp} alt="mute"/>
                </div>

                <div className={"captions-button"}
                     onClick={() => {
                         captionsVideo();
                     }}>
                    <img src={captions ? captionsSolid : captionsRegular} alt="captions"/>
                </div>
            </div>
        </div>
    );
};

export default Controls;
