import React from "react";

const Preloader = ({ ready }) => {
    return (
        <div>
            Preloader
        </div>
    );
};

export default Preloader;
