import initialState from '../../redux/initialState'
import * as types from '../../actions/actionTypes';
import deepClone from "deep-clone";


const video = (state = initialState.video, action)=> {
    let st = deepClone(state);
    switch (action.type) {
        // case types.GET_VIDEO_SUCCESS:
        //     st = action.payload
        //     return st;
        case types.SET_VIDEO:
            st = action.payload;
            return st;
        default:
            return state;
    }
};
export default video

