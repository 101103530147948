import axios from 'axios';
import * as types from '../../actions/actionTypes';


export const getItemsSuccess = (items)=>{
    return{
        type:types.GET_ITEMS_SUCCESS,payload:items
    }
};

export const getItemsLaunched = (options) => ({
    type: types.GET_ITEMS_LAUNCHED,
    payload:options
});

export const getItems= (action)=>{
    return axios.get(action.payload.url+'/api/items').then((r)=>{
       return r
   })
};


