import React from 'react';
import ReactDOM from 'react-dom';
import Immutable from 'immutable';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {createStore, compose, applyMiddleware} from "redux";
import createSagaMiddleware from 'redux-saga';
import rootReducer from "./redux/rootReducer";
import itemSagas from "./components/Items/itemSagas";
import "./styles/css/app.css";
import videoSaga from "./components/Video/videoSaga";
import {shallowEqual, useSelector} from "react-redux";


const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
);


store.subscribe(()=>{

const state = store.getState();

// console.log(localStorage)

    // console.log(state.get('userdata'));


})

sagaMiddleware.run(itemSagas);
sagaMiddleware.run(videoSaga);


const rootEl = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <App options={{
            url: rootEl.dataset.url,
            test: 'test',
            videomode:'offline_',
            basePath: rootEl.dataset.basepath,
            initialLang: rootEl.dataset.basepath === '/story' ? 'en' : 'es',
            playbackrate:1,
            debug:false,
            shortvideos:false,
        }}/>
    </Provider>, rootEl);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
