import React, {useEffect, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import countries from 'i18n-iso-countries'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));


const SelectCountry = ({
                           handleChange,
                           handleBlur,
                           values,
                           value,
                           errors,
                           touched,
                           field
                       }) => {

    const {lang, options} = useSelector(
        state => ({
            lang: state.get("lang"),
            options: state.get("options")
        }),
        shallowEqual
    );
    const [locale, setLocale] = useState(lang === 'es' ? "ES" : "US");
    useEffect(() => {


        // countries.registerLocale(require("i18n-iso-countries/langs/"+lang+".json"));
    }, [])
    return (
        <fieldset>
            <label htmlFor={field.slug}>{field.label[lang]}</label>
            {/*<br/>*/}
            <div className="select">
                <select
                    type={field.type}
                    name={field.slug}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value}
                >

                    {Object.values(countries.getNames( lang)).sort().map((c,index)=>{
                            // console.log(c);
                        return  (<option  key={'country-'+index}value={c}>
                            {c}
                        </option>)
                    })
                    }

                </select>
            </div>
            <div className={'error'}>
                {errors[field.slug] && touched[field.slug] && errors[field.slug]}

            </div>
        </fieldset>
    );
};

export default SelectCountry;
