import React from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as types from '../../actions/actionTypes';



const Lang = ()=>{

    const {lang, options} = useSelector(state => ({
        lang: state.get('lang'),
        options: state.get('options')
    }), shallowEqual);

    const dispatch = useDispatch();


    return(
        <div className={"lang"} onClick={()=>{
            dispatch({type:types.TOGGLE_LANG});
        }}>
            {lang}
        </div>
    )
}

export default Lang
