import React, {useEffect} from 'react';
import * as types from "../../actions/actionTypes";
import {shallowEqual, useDispatch, useSelector} from "react-redux";



const FormInitial = ({item,index})=>{

    const dispatch = useDispatch();

    const {options, device} = useSelector(
        state => ({
            options: state.get("options"),
            device: state.get("device"),
            forms:state.get('forms')
        }),
        shallowEqual
    );


    useEffect(()=>{
        if(index===0){
            dispatch({type:types.SET_ALL_FORMS_INACTIVE});
            dispatch({type:types.SET_ACTIVE_FORM, payload:item.id});
            dispatch({type:types.SHOW_FORMS, payload: true});
        }


    },[])
    return (
        <div className={'item ' + item.type} id={'item-' + item.id} key={'item-' + index} data-order={item.order}
             style={{zIndex:99-item.order,  display:index!==0 ? 'none':'block' }}>
            <img src={item.poster} alt=""/>

        </div>
    )
}

export default FormInitial
