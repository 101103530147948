import React, {useEffect} from 'react';
import Zones from "../Zones";
import Captions from "../Captions/Captions";
import {useSize} from "react-hook-size";


const Image = ({item, setReady,index,moveForward,resetChapter}) => {



    useEffect(() => {


        return () => {

        }
    })
    return (
        <div className={"images-zones-container"}>

            <div className={'modal-container'}>

            </div>



            <div className={'zones-container'}>
                {item.zones ? <Zones item={item} moveForward={moveForward} resetChapter={resetChapter}/> : <></>}
            </div>

            {item.captions ?
                <Captions captions={item.captions} />

                :
                <></>

            }
            <div className={'image-container'}>
                <img src={item.src} alt=""/>

                {item.audio && index === 0 ?
                    <audio src={item.audio} autoPlay={true} loop={true}></audio>
                    :
                    <></>
                }

            </div>
        </div>
    )
}

export default Image
