import {call, put,all,takeLatest,takeEvery} from "@redux-saga/core/effects";
import {getVideo, getVideoError, getVideoSuccess} from './videoActions';
import * as types from '../../actions/actionTypes';



function* fetchVideo(action){


    try{
        const resp = yield call(getVideo,action);
        yield put(getVideoSuccess(resp))
    }
    catch(e){
        yield put(getVideoError())


    }
}



export default function* videoSaga() {
    yield all([
        takeEvery(types.GET_VIDEO_LAUNCHED, fetchVideo)
    ]);
}
