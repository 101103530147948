import React from "react";
import image from "../../../btn-ver-material.svg"

const Link = ({ tag ,width,height ,handleOpenModal, handleCloseModal}) => {



    const tagStyle = {
        position:'absolute',
        top: (height / 100 * tag.y) + 'px',
        left: (width / 100 * tag.x) + 'px',

    };
    return (
        <div className={"icon-tags btn-ver-material bounceInRight"} id={tag.id} style={tagStyle} onClick={()=>{

            if(tag.type!=='warning'){
                handleOpenModal(tag);
            }

        }}>
            {/*image*/}
            { tag.type !== "warning" ?
                <img  src={image} alt=""/>
                :
            <div>
                Warning
            </div>
            }



        </div>
    );
};

export default Link;
