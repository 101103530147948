let initialState = {
        "choices": [
            {
                "id": 1,
                "name": "choice-2",
                "video-yt": "",
                "next": [
                    2,
                    3
                ]
            }
        ],
        "device": {},
        "forms": [
            {
                "id": 1008,
                "type": "form",
                "action": "userdata",
                "active": false,
                "slug": "userdata",
                "width": 30,
                "title": {
                    "es": "¡Te damos la bienvenida al mundo de Amorfiesta!",
                    "en": "You are welcome to the world of Amorfiesta! "
                },
                "disallowclose": true,
                "fields": [
                    {
                        "label": {
                            "es": "Nombre o Nick",
                            "en": "Nick"
                        },
                        "slug": "name",
                        "type": "input",
                        "options": [],
                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Email",
                            "en": "EMail"
                        },
                        "type": "input",
                        "slug": "email",
                        "options": [],
                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "País",
                            "en": "Country"
                        },
                        "slug": "country",
                        "type": "select-country",
                        "options": [],
                        "required": true,
                        "dValue": "1",
                    },
                ]
            },
            {
                "id": 1009,
                "type": "form",
                "action": "userdata",
                "active": false,
                "slug": "userdata",
                "width": 30,
                "title": {
                    "es": "¡Te damos la bienvenida al mundo de Amorfiesta!",
                    "en": "You are welcome to the world of Amorfiesta! "
                },
                "disallowclose": true,
                "fields": [
                    {
                        "label": {
                            "es": "Nombre o Nick",
                            "en": "Nick"
                        },
                        "slug": "name",
                        "type": "input",
                        "options": [],
                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Email",
                            "en": "EMail"
                        },
                        "type": "input",
                        "slug": "email",
                        "options": [],
                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "País",
                            "en": "Country"
                        },
                        "slug": "country",
                        "type": "select-country",
                        "options": [],
                        "required": true,
                        "dValue": "1",
                    },
                ]
            },
            {
                "id": 1010,
                "name": "Test 00 Embarazo Adolescente",
                "type": "test",
                "active": false,
                "slug": "test-0",
                "width": 80,
                "minimumApprove": 60,
                "disallowclose": true,
                "title": {
                    "es": "¿Cuánto sabes de Salud Sexual?",
                    "en": "How much do you know about sexual health?"
                },
                "success": {
                    'legend': {
                        "es": '<h2>&iexcl;Felicidades!</h2><p>A pesar de lidiar con emociones complicadas entiendes que cada persona debe explorar sus propios valores, actitudes, creencias e ideales sobre el amor y la sexualidad, sin vulnerar a otros. Sabes que los deseos, fantasías, placeres y preferencias sexuales se comparten con quién queremos, solo cuando lo deseemos y nunca por presión.</p>\n' +
                            '<p>Te identificas con <b>Otis, Maeve y Eric.</b> Protagonistas de <i>Sex Education</i> que, como tú, superan un montón de dificultades mientras reconocen sus propios deseos y emociones, aprenden a amar y a experimentar una sexualidad responsable.</p>\n' +
                            '<p>&iexcl;Contin&uacute;a as&iacute; y desbloquear&aacute;s las rutas de <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorifesta!</a> </p>',
                        "en": '<p>Congratulations! Despite dealing with complicated emotions, you are able to understand that each person has to explore their own values, attitudes, beliefs and ideals about love and sexuality, without harming others. You know that desires, fantasies, pleasures and sexual preferences are shared with who we want, only when we want it and never by pressure.</p>\n' +
                            '<p>You identify with <b>Otis</b>, <b>Maeve </b>and <b>Eric</b>, the main characters of the series <i>Sex Education</i>. Like you, they are able to overcome their difficulties while they recognize their own desires and emotions, learn to love and experience responsible sexuality.</p>\n' +
                            '<p>Continue like this and you will unlock the <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorifesta\'s</a> route!.</p>',
                    },
                    "image": "/assets/img/tests/img-test0.jpg",
                    'epigraph': {
                        "es": 'Eric, Maeve y Otis de la serie de <i>Sex Education</i>',
                        "en": 'Eric, Maeve y Otis de la serie de <i>Sex Education</i>',
                    },

                },
                "error": {
                    'legend': {
                        "es": '<p>Parece que tienes problemas con tu sexualidad y a veces olvidas que placer y autocuidado van de la mano. Según tus respuestas, aún estás descubriendo quién eres y qué deseas. Es normal. ¡El cambio hace parte de la vida! El problema es que cuando no te cuidas, tus gustos, deseos, intereses, actos... pueden ir en tu contra.</p>\n' +
                            '<p>¿Has visto <b>Juno</b>? La historia de la adolescente que se deja llevar por sus impulsos y queda embarazada termina bien porque ella toma decisiones de autocuidado que le permiten retomar el rumbo de su vida. Pero muchas de estas historias no tienen un final feliz.</p>' +
                            '<p>¡Vuelve al principio y tendrás otra oportunidad para desbloquear la ruta de Amorfiesta!</p>',
                        "en": '<p>It seems that you are having problems with your sexuality and sometimes you forget that pleasure and self-care should go together. According to your answers, you are still discovering who you are and what you want. That\'s normal change is part of life! The problem is that when you don\'t take care of yourself, your tastes, desires, interests, actions... can go against you.</p>\n' +
                            '<p>Have you seen <strong>Juno</strong>? The story of the teenager who gets carried away by her impulses and becomes pregnant. It ends well because she makes self-care decisions that allow her to get back on track with her life. But many of these stories do not have a happy ending</p>' +
                            '<p>Go back to the beginning and you will have another chance to unlock the <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorifesta\'s</a> route!.</p>',
                    },
                    "image": "/assets/img/tests/img-test0-mal.jpg",
                    'epigraph': {
                        "es": 'Juno MacGuff de la película <i>Juno</i>',
                        "en": 'Juno MacGuff de la película <i>Juno</i>',
                    },
                },
                "fields": [
                    {
                        "label": {
                            "es": "<em>¡Ups! ¡Las vidas de Petra y Pascacio cambiarán para siempre!\n</em>",
                            "en": "<em>Oops! Petra and Pascacio lives will change forever!</em>"
                        },
                        "type": "label",
                        "correct": [0],
                        "options": [],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Por qué no se detuvieron a tiempo?",
                            "en": "Why didn’t they stop in time?"
                        },
                        "slug": "radio-1",
                        "type": "radio",
                        "correct": [1, 2],
                        "options": [
                            {
                                "label": {
                                    "es": "Fue culpa de Petra. Debió ser más contundente. «El hombre propone y la mujer dispone»",
                                    "en": "It was Petra’s fault. She should have been more determined. «The man proposes and the woman arranges»"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Fue culpa de Pascacio que sobrepasó los límites y no respetó a Petra",
                                    "en": "It was Pascacio’s fault because he exceeded the limits and for not respecting the discomfort and doubts Petra was having"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Las hormonas son fuertes a esa edad y si no tienes información adecuada es muy difícil parar",
                                    "en": "The hormones are so strong at that age that if you don’t have proper information it is so difficult to stop"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Es difícil embarazarse a la primera. ¡Solo tuvieron mala suerte!",
                                    "en": "Because most of the time it’s hard to get pregnant, they just had bad luck!"
                                },
                                "correct": false,
                            }

                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Por qué Petra no fue más contundente con sus límites?",
                            "en": "Why was Petra not more determined?"
                        },
                        "slug": "radio-2",
                        "type": "radio",
                        "correct": [0, 2, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "No le han enseñado a poner límites y a decir <i>No es No</i>",
                                    "en": "Because she hasn&rsquo;t been taught to set their limits and say <em>No means No</em>"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "¡Porque también tenía ganas! Si no quería, ¿pa´que llegó tan lejos?",
                                    "en": "Because she wanted too! If she didn’t want to, then why did she go so far?"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No creo que sea culpa de Petra sino de Pascacio",
                                    "en": "I don’t think it’s Petra’s fault, but Pascacio’s"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "No creo que sea culpa de ninguno. Simplemente no sabían cómo detenerse",
                                    "en": "I don’t think it’s the fault of any of them. Simply they didn’t know how to stop"
                                },
                                "correct": true,
                            }

                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Y Pascacio… ¿Por qué no se detuvo?",
                            "en": "Why didn’t Pascacio stop?"
                        },
                        "slug": "radio-3",
                        "type": "radio",
                        "correct": [1, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "Los chicos tienen más energía sexual desde pequeños. ¡Es normal!",
                                    "en": "Because he is a man and boys have more sexual energy since they are little. It’s normal!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No le han enseñado sobre el consentimiento e ignora que las personas pueden cambiar de opinión en cualquier momento del acto",
                                    "en": "Because he hasn’t been taught about consent and he ignores that people can change their opinion at any moment of the act"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "No creo que sea culpa de Pascacio sino de Petra",
                                    "en": "I don’t think it's the fault of Pascasio but of Petra"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No creo que sea culpa de ninguno. Simplemente no sabían cómo detenerse",
                                    "en": "I don’t think it’s the fault of any of them. It happened because they didn’t have tools to stop"
                                },
                                "correct": true,
                            }

                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Pudiste seleccionar la otra opción ¿Por qué escogiste este camino?",
                            "en": "You were able to select the other option. Why did you choose this path?"
                        },
                        "slug": "radio-4",
                        "type": "radio",
                        "correct": [0, 2, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "Cuando has metido la pata tienes que responsabilizarte de tus actos",
                                    "en": "Because when you have screwed up you have to take responsibility for your actions"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "El mundo está lleno de mujeres que salen adelante con sus hijos. ¡No es tan grave!",
                                    "en": "Because the world is full of young women who move on with their children. It’s not so serious!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Porque quería ver qué sucedía",
                                    "en": "Because I wanted to see what happened"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Es un juego y no hay ningún riesgo al tomar esta decisión",
                                    "en": "Because it is a game and I don’t have any risk in making this decision"
                                },
                                "correct": true,
                            }
                        ],

                        "required": true,
                        "dValue": "",
                    },
                ]
            },
            {
                "id": 1013,
                "name": "Test 1 Estereotipos PETRA",
                "type": "test",
                "active": false,
                "slug": "test-1-fem",
                "width": 80,
                "minimumApprove": 60,
                "disallowclose": true,
                "title": {
                    "es": "¿Qué es ser Mujer?",
                    "en": "What is being a woman?"
                },
                "success": {
                    'legend': {
                        "es": '<p>&iexcl;Felicidades, eres la chica Amorfiesta! Te identificas con <strong>Kiki, </strong>la protagonista de una de las pel&iacute;culas de Hayao Miyazaki. Esta bruja adolescente es independiente y sue&ntilde;a con volar. Tiene muchos problemas para encontrar su poder personal, pero no se desanima. Al contrario, utiliza su creatividad para lograr sus objetivos. </p>' +
                            '<p>Igual que Kiki, entiendes la importancia de ser auténtica y soñar. Aunque a veces el mundo es difícil no abandonas tus deseos y sigues luchando por ganar tus propios espacios.</p>' +
                            '<p>¡Sigue así! Serás capaz de ayudar a chicas como Petra a desbloquear las rutas de <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>.</p>',
                        "en": '<p>Congratulations, you are the Amorfiesta girl! You identify with <strong>Kiki</strong>, the main character of Hayao Miyazaki&rsquo;s film. This teenage witch is independent and dreams of flying. He has a lot of trouble finding his personal power, but he is not discouraged. On the contrary, she uses her creativity to achieve her goals.</p>\n' +
                            '<p>Like Kiki, you understand the importance of being authentic and dreaming. Although sometimes the world is difficult, you do not leave your dreams and you keep fighting to win your space.</p>\n' +
                            '<p>Keep it up! You will be able to help girls like Petra to unblock the <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta\'s</a> route!</p>',
                    },
                    "image": "/assets/img/tests/img-test1-est-fem.png",
                    'epigraph': {
                        "es": 'Kiki de la película <i>Kiki: entregas a domicilio</i>',
                        "en": 'Kiki de la película <i>Kiki: entregas a domicilio</i>',
                    },

                },
                "error": {
                    'legend': {
                        "es": '<p>&iexcl;<em>Patriarcalito</em> te est&aacute; complicando la vida! Sientes que nadie te entiende y como respuesta callas y te retraes, alej&aacute;ndote de quienes te aman. Parece que abandonas tus sue&ntilde;os y en medio del ruido externo y la presi&oacute;n, no logras levantar tu voz.</p>' +
                            '<p>Hay una <em>Sirenita</em> llamada <strong>Ariel</strong> a la que le sucedi&oacute; algo parecido. Era una joven libertaria, creativa, inconformista, pero ante la intransigencia de su padre, opt&oacute; por huir tras un pr&iacute;ncipe olvidando sus talentos&hellip; y perdi&oacute; su voz. La buena noticia: &iexcl;No tienes que hacer eso! Muchas f&eacute;minas se enfrentan al reto de ser escuchadas sin abandonar sus sue&ntilde;os o su identidad.</p>' +
                            '<p>Para que reflexiones y ayudes a Petra a desbloquear las rutas de <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>, volverás al inicio. Tanto en la vida como en el juego podemos empezar de nuevo. ¡Buena suerte!</p>',
                        "en": '<p><em>Patriarcalito </em>is complicating your life! Sometimes You feel that no one understands you and in response you say nothing and you withdraw, separating yourself from those who love you. It seems that you are abandoning your dreams and amid the external noise and pressure, you can&rsquo;t raise your voice.</p>' +
                            '<p>There is a <em>Little Mermaid</em> named <strong>Ariel </strong>to whom something similar happened. She was a young libertarian, creative, nonconformist, but faced with her father\'s intransigence, she chooses to flee after a prince, forgets her wishes and loses her voice. The good news: You don\'t have to do that! Many women face the challenge of being heard without abandoning their dreams or their identity.</p>' +
                            '<p>For you to reflect and help Petra to unlock the <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta\'s</a> route, you will return to the beginning. Both in life and in the game we can start over. Good luck!</p>',
                    },
                    "image": "/assets/img/tests/img-test1-est-fem-mal.png",
                    'epigraph': {
                        "es": 'Ariel de la película <i>La sirenita</i>',
                        "en": 'Ariel de la película <i>La sirenita</i>',
                    },
                },
                "fields": [
                    {
                        "label": {
                            "es": "<em>Petra tiene muchos problemas. No le permiten hacer cosas que a su hermano sí, tiene granitos y, por si fuera poco, sus amigas se burlan de ella...</em>",
                            "en": "<em>Petra has many problems. She is not allowed to do things that her brother can, she has pimples and, if that was not enough, her friends make fun of her...</em>"
                        },
                        "type": "label",
                        "correct": [0],
                        "options": [],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Por qué Petra cede a la presión del grupo?",
                            "en": "Why did Petra yield to the pressure of the group?"
                        },
                        "slug": "radio-55",
                        "type": "radio",
                        "correct": [1, 2, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "Sus amigas tienen razón. Una chica no debe comer tanto",
                                    "en": "Because the friends of Petra are right. A girl should not eat so much"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Con tanta burla se le quitó el hambre",
                                    "en": "Because before such mockery the hunger went away"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Porque imagina un grano tamaño gigante en forma de helado que explota en su cara. ¡Si al menos hubiera sido hombre no tendría que renunciar siempre!",
                                    "en": "Because she imagines a giant size pimple shaped like ice cream that explodes in her face. If she had been a man at least, she would not have to always give up!"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "La han callado tantas veces que no tiene experiencia en hablar sincera y claramente de lo que le molesta",
                                    "en": "Because she has been silenced so many times that she has no experience in speaking sincerely and clearly about the issues that bother her"
                                },
                                "correct": true,
                            }

                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Crees que hubiera sido mejor hablar sinceramente de sus emociones con Maricarmen y Violeta?",
                            "en": "Do you think it would have been better to tell Maricarmen and Violeta the truth?"
                        },
                        "slug": "radio-58",
                        "type": "radio",
                        "correct": [3],
                        "options": [
                            {
                                "label": {
                                    "es": "No. Porque las amigas son unas burlonas y no van a entender",
                                    "en": "No. Because her friends are mocking her and will not understand anything"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No. «La ropa sucia se lava en casa». Uno no gana nada con contar sus problemas a gente que no puede resolverlos",
                                    "en": "No. Because \"dirty laundry is washed at home\" and you do not gain anything by telling your problems to people that can’t solve them"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No. ¡Qué vergüenza! Mejor que no se den cuenta",
                                    "en": "No. What an embarrassment! Better not to be noticed"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Sí. Pero Petra aún no sabe cómo hacerlo. Tiene que aprender a expresar sus emociones",
                                    "en": "Yes. But Petra still does not know how to do it. She has to learn to express her emotions"
                                },
                                "correct": true,
                            },


                        ],
                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Crees que las mujeres tienen que hacer sacrificios para ser aceptadas?",
                            "en": "Do you think women have to make sacrifices to be accepted?"
                        },
                        "slug": "radio-60",
                        "type": "radio",
                        "correct": [2, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "No tienen que hacerlos... ¡Pero les gusta!",
                                    "en": "They don’t have to. But they like to do it!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Sí. Las mujeres siempre han tenido que sacrificar algo para ser aceptadas",
                                    "en": "Yes. Women have always had to sacrifice something to be accepted"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Sí. Pero a todos nos toca sacrificar un poco para avanzar. No solo a las mujeres",
                                    "en": "Yes. But we all have to sacrifice a little bit to move on. Not only women"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Sí. Pero es momento de replantearse la idea de «sacrificio», para ser felices",
                                    "en": "Yes. But it’s time to rethink the idea of “sacrifice” to be happy"
                                },
                                "correct": true,
                            },


                        ],
                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Y los hombres?",
                            "en": "And men?"
                        },
                        "slug": "radio-62",
                        "type": "radio",
                        "correct": [2, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "No tienen que hacerlos... ¡Pero les gusta!",
                                    "en": "They don’t have to. But they like to do it!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Sí. Los hombres siempre han tenido que sacrificar algo para ser aceptados",
                                    "en": "Yes. Men have always had to sacrifice something to be accepted"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Sí. Pero a todos nos toca sacrificar un poco para avanzar. No solo a los hombres",
                                    "en": "Yes. But we all have to sacrifice a little bit to move on. Not only men"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Sí. Pero es momento de replantearse la idea de «sacrificio», para ser felices",
                                    "en": "Yes. But it’s time to rethink the idea of “sacrifice” to be happy"
                                },
                                "correct": true,
                            },

                        ],

                        "required": true,
                        "dValue": "",
                    },
                ]
            },
            {
                "id": 1023,
                "name": "Test 3 Acoso Callejero PETRA",
                "type": "test",
                "active": false,
                "slug": "test-3-fem",
                "width": 80,
                "minimumApprove": 60,
                "disallowclose": true,
                "title": {
                    "es": "¿QUÉ SABES SOBRE EL ACOSO?",
                    "en": "WHAT DO YOU KNOW ABOUT HARASSMENT?"
                },
                "success": {
                    'legend': {
                        "es": '<h2>&iexcl;Felicidades!</h2><p>Tus inquietudes e inconformidad con las normas de <em>Patriarquilandia</em> te ayudan a desafiar las amenazas y expresarte libremente. Tus respuestas te identifican con <strong>Mafalda</strong>. Como ella, defiendes tus derechos estableciendo l&iacute;mites. </p>' +
                            '<p>&iexcl;Sigue as&iacute;! Ser&aacute;s capaz de ayudar a chicas como Petra a cuidarse, defenderse y desbloquear la ruta de <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>.</p>',
                        "en": '<p>Congratulations! Your concerns and disagreement with the rules <em>of Patriarquiland </em>help you defy the threats and express yourself freely. Your answers identify you with <strong>Mafalda</strong>. Like her, you defend your rights by setting limits. </p>\n' +
                            '<p>Keep it up! You will be able to help girls like Petra to take care of themselves, defend themselves and unlock the <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>&acute;s route.</p>',
                    },
                    "image": "/assets/img/tests/img-test3-acoso-fem.png",
                    'epigraph': {
                        "es": 'Mafalda de la tira cómica <i>Mafalda</i>',
                        "en": 'Mafalda de la tira cómica <i>Mafalda</i>',
                    },

                },
                "error": {
                    'legend': {
                        "es": '<p>Parece que <em>Patriarcalito</em> te est&aacute; complicando la vida&hellip; Es tiempo de detener el abuso y enfrentar a los acosadores para construir un mundo incluyente y respetuoso. </p>' +
                            '<p>&iquest;Recuerdas a <strong>Aurora</strong>? La princesa durmiente de Disney vive desorientada, enga&ntilde;ada y &aacute;vida de ayuda. No puede despertarse hasta que un pr&iacute;ncipe la besa. Lo que no nos cuentan es si ella desea ese beso. T&uacute;&hellip; &iquest;Quieres repetir estas historias? &iquest;Qu&eacute; te besen o te toquen sin consentimiento? &iquest;O prefieres gritar: &iexcl;Basta!? </p>' +
                            '<p>Para que pongas l&iacute;mites al acoso, al abuso y desbloquees la ruta de <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a> te invitamos a volver al principio.</p>',
                        "en": '<p>It seems that <em>Patriarcalito </em>is complicating your life... </p>' +
                            '<p>Do you remember <strong>Aurora</strong>? Disney\'s sleeping princess lives disoriented, deceived and eager for help. She cannot wake up until a prince kisses her. What they don\'t tell us is if she wants that kiss. You... Do you want to repeat these stories? To be kissed or touched without your consent? Or do you prefer to shout: Enough!? </p>' +
                            '<p>So that you put limits to harassment, abuse and unlock the <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>&acute;s route we invite you to return to the beginning.</p>',
                    },
                    "image": "/assets/img/tests/img-test3-acoso-fem-mal.png",
                    'epigraph': {
                        "es": 'Aurora de la película <i>La bella durmiente</i>',
                        "en": 'Aurora de la película <i>La bella durmiente</i>',
                    },
                },
                "fields": [
                    {
                        "label": {
                            "es": "«Uy mami, qué tetas», le dice el hombre a Petra y ella se queda callada.",
                            "en": "\"Baby! What a tits!\" says the man to Petra and she doesn't say anything."
                        },
                        "type": "label",
                        "options": [],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Por qué dejas que Petra aguante los abusos sin decir o hacer algo? ",
                            "en": "Why do you let Petra endure this situation repeatedly without saying or doing something?"
                        },
                        "slug": "radio-14",
                        "type": "radio",
                        "correct": [3],
                        "options": [
                            {
                                "label": {
                                    "es": "¿Qué hacer? No es ni la primera ni la única vez y una chiquilla no va a cambiar nada",
                                    "en": "What to do? It has always been that way and a little girl will not change anything"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Es mejor no buscar problemas. Una no sabe cómo reacciona el tipo",
                                    "en": "It is better not to look for problems. You don’t know how will the man react"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "¿Quién manda a Petra a vestirse con ese pantalón tan apretado?",
                                    "en": "Who told Petra to dress in such tight pants?"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Porque quería ver qué onda con este test",
                                    "en": "Because I wanted to see what's up with the test"
                                },
                                "correct": true,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "De la afirmación: «Los piropos son parte de la cultura popular», piensas que...",
                            "en": "«Compliments are part of popular culture». What do you think about this statement?"
                        },
                        "slug": "radio-51",
                        "type": "radio",
                        "correct": [0,1,2],
                        "options": [
                            {
                                "label": {
                                    "es": "Es cierto. Los piropos son poemas para halagar a las mujeres",
                                    "en": "I agree. The flatteries are poems to compliment women"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Piropos, miradas morbosas, silbidos… son parte de la cultura de violencia",
                                    "en": "Flatteries, morbid looks, whistles... are forms of harassment and violence"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Los piropos muestran que las ciudades son menos seguras para mujeres que para hombres",
                                    "en": "The flatteries reflect that cities are less safe for women than for men"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Los piropos cosifican a las mujeres y resulta que las mujeres no son adornos",
                                    "en": "The flatteries objectify women, but women are not ornaments"
                                },
                                "correct": false,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Sobre el acoso, te parece que…",
                            "en": "About harassment, do you think ..."
                        },
                        "slug": "radio-15",
                        "type": "radio",
                        "correct": [1, 2, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "Es sabio callar y tratar de alejarse del agresor, como hizo Petra",
                                    "en": "It is wise to shut up and try to get away from the aggressor, as Petra did"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Es necesario identificar estrategias de respuesta segura: ironía, defensa personal, frases sobre derechos e igualdad, identificación pública del agresor",
                                    "en": "It is necessary to identify safe response strategies: irony, self defense, discourses on rights and equality, public identification of the aggressor"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Es necesario empoderar a niñas y mujeres para defenderse",
                                    "en": "It is necessary to empower girls and women to defend themselves"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Es necesario enseñar a niños y hombres a respetar los límites",
                                    "en": "It is necessary to teach children and men to respect limits"
                                },
                                "correct": true,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Si te permite devolver la historia y tomar otra decisión ¿Qué harías?",
                            "en": "If it allows you to return the story and make another decision, what would you do?"
                        },
                        "slug": "radio-16",
                        "type": "radio",
                        "correct": [1, 2, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "Lo mismo. No hay que exagerar con este tema ¡ni que la hubiera violado!",
                                    "en": "The same. Do not exaggerate with this issue, it is not like she was violated!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Evidencio al tipo gritándole ¡abusador, acosador! para que le dé vergüenza",
                                    "en": "I would unmask the guy yelling at him: abuser, stalker! to make him ashamed"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Le tomo una foto y le digo que la voy a publicar con el <strong>#acosador</strong>",
                                    "en": "I would take a picture and tell him that I will publish it with the <strong>#stalker</strong>"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Le explico mis derechos y le exijo que me respete",
                                    "en": "I would explain to him my rights and I would demand respect"
                                },
                                "correct": true,
                            }
                        ],

                        "required": true,
                        "dValue": "",
                    }
                ]
            },
            {
                "id": 1045,
                "name": "Test 3 Nuevas Masculinidades PASCACIO",
                "type": "test",
                "active": false,
                "slug": "test-3-masc",
                "width": 80,
                "minimumApprove": 60,
                "disallowclose": true,
                "title": {
                    "es": "¿Qué son las Nuevas Masculinidades?",
                    "en": "What are the New Masculinities?"
                },
                "success": {
                    'legend': {
                        "es": '<p>&iexcl;Eres un chico reconciliado con su masculinidad! Tus respuestas te identifican con <strong>Spiderman</strong>, un superh&eacute;roe capaz de mostrar su humanidad y cari&ntilde;o a amigos y familiares, que se atreve a cuestionar la imagen del hombre viril y ausente. Parece que para ti es importante cuidar de otras personas y has aprendido que la equidad es el mejor camino al bienestar colectivo.</p>' +
                            '<p>Reconocer que la actitud de los amigos de Pascacio es ego&iacute;sta, da&ntilde;ina y retr&oacute;grada, muestra tu inteligencia emocional y aprendizajes. </p>' +
                            '<p>&iexcl;Felicidades! Es importante mantener esta mirada para desbloquear la ruta de <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>.</p>',
                        "en": '<p>You are the boy reconciled with his masculinity! Your answers identify you with <strong>Spiderman</strong>, a superhero capable of showing his humanity and affection to friends and family, who dares to question the image of the virile and absent man. It seems that for you it is important to take care of other people and you have learned that equity is the best way to collective well-being.</p>' +
                            '<p>Recognize that the attitude of Pascacio\'s friends is selfish, harmful and retrograde, shows your emotional intelligence and learning. </p>' +
                            '<p>Congratulations! It is important to maintain this view to unlock the <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>&acute;s route.</p>',
                    },
                    "image": "/assets/img/tests/img-test3-acoso-masc.png",
                    'epigraph': {
                        "es": 'Spiderman de la película <i>Spiderman</i>',
                        "en": 'Spiderman de la película <i>Spiderman</i>',
                    },

                },
                "error": {
                    'legend': {
                        "es": '<p>Parece que <em>Patriarcalito</em> te ha hecho creer que eres el centro del mundo y puedes hacer lo que quieras sin pensar en tus parejas, compa&ntilde;eras, amigas y conocidos. Debes saber que esa actitud ego&iacute;sta genera violencia y desigualdad.</p>' +
                            '<p>Como <strong>Bart</strong> y <strong>Homero Simpson</strong>, te parece super <em>cool </em>ser irresponsable e infantil frente a la vida y tus relaciones, transgrediendo l&iacute;mites propios y ajenos. &iquest;Qu&eacute; tal si abandonas tus privilegios y usas la creatividad para construir un mundo m&aacute;s equitativo?</p>' +
                            '<p>Para que no repitas los patrones de <em>Patriarcalito </em>y desbloquees la ruta de <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a> te invitamos a que vuelvas al inicio y reflexiones sobre otras formas de ser hombre.</p>',
                        "en": '<p>It seems that <em>Patriarcalito </em>has made you believe that you are the center of the world and you can do whatever you want without thinking about your partners, friends and acquaintances. You should know that this selfish attitude generates violence and inequality.</p>' +
                            '<p>Like <strong>Bart </strong>and <strong>Homer Simpson</strong>, you find it super cool to be irresponsible and childish in front of life and your relationships, transgressing your own and other people\'s limits. How about you abandon your privileges and use creativity to build a more equitable world?</p>' +
                            '<p>So you don\'t repeat <em>Patriarcalito </em>patterns and unlock the <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>\'s route, we invite you to go back to the beginning and reflect on other ways of being a man.</p>',
                    },
                    "image": "/assets/img/tests/img-test3-acoso-masc-mal.png",
                    'epigraph': {
                        "es": 'Bart y Homero Simpson de la serie animada <i>Los Simpson</i>',
                        "en": 'Bart y Homero Simpson de la serie animada <i>Los Simpson</i>',
                    },
                },
                "fields": [

                    {
                        "label": {
                            "es": "Pascacio se da cuenta de que El Príncipe embarazó a su novia y no quiere hacerse cargo.",
                            "en": "Pascacio realizes that the Prince got his girlfriend pregnant and he doesn’t want to take responsibilities for it."
                        },
                        "type": "label",
                        "options": [],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Por qué un muchacho deja embarazada a su compañera y huye?",
                            "en": "Why does a boy make his partner pregnant and run away?"
                        },
                        "slug": "radio-8",
                        "type": "radio",
                        "correct": [0],
                        "options": [
                            {
                                "label": {
                                    "es": "Se cree que los hombres no tienen nada que perder. Huir es una forma simbólica de ganar",
                                    "en": "It is believed that men have nothing to lose. Running away is a symbolic way to win"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Aunque digan lo que digan, los hijos son de las mujeres ¿Por qué no se cuidan?",
                                    "en": "Even if they say what they say, the children belong to women. Why don't they take care of themselves?"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "A los hombres se les enseña a ser atendidos y hacer su voluntad sin pensar en sus compañeras",
                                    "en": "Men learned to be taken care of and do our will without thinking about our partners"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Un hijo en la adolescencia afecta la vida del muchacho. Si ella pudiera también lo haría... ¿O no?",
                                    "en": "A son in adolescence affects the boy's life. If she could, she would too... Or not?"
                                },
                                "correct": false,
                            },
                        ],
                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Cuidar es…",
                            "en": "Caring is ..."
                        },
                        "slug": "radio-9",
                        "type": "radio",
                        "correct": [1,3],
                        "options": [
                            {
                                "label": {
                                    "es": "No dejar que la otra persona salga sola y controlarla para protegerla",
                                    "en": "Not letting the other person to go out alone and control them to protect them"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Atender a las personas cuando están frágiles y vulnerables o cuando necesitan y piden apoyo",
                                    "en": "Attending to the other when they are fragile and vulnerable or when they need and ask for support"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Revisar el móvil y las redes sociales de otras personas",
                                    "en": "Checking other people’s phone and social networks"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Aceptar la responsabilidad compartida de los actos en los que participan varias personas",
                                    "en": "Accepting shared responsibility for acts involving several people"
                                },
                                "correct": true,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Amar es…",
                            "en": "Loving is..."
                        },
                        "slug": "radio-10",
                        "type": "radio",
                        "correct": [2,3],
                        "options": [
                            {
                                "label": {
                                    "es": "Dar regalos lindos, dedicar canciones y halagar",
                                    "en": "Give cute gifts, dedicate you songs and flatter you"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Hacer que la otra persona te necesite",
                                    "en": "Make other one needs you"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Empatizar con el dolor de las personas que quieres",
                                    "en": "Empathize with the pain of the people you love"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Encontrar juntos soluciones a problemáticas cotidianas",
                                    "en": "Find solutions to everyday problems together"
                                },
                                "correct": true,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Si pudieras volver atrás ¿Qué le dirías a tus amigos?",
                            "en": "If you could go back, what would you say to your friends?"
                        },
                        "slug": "radio-53",
                        "type": "radio",
                        "correct": [1,2],
                        "options": [
                            {
                                "label": {
                                    "es": "Lo mismo ¡No es mi problema!",
                                    "en": "The same. It is not my problem!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "¿¡Cómo es posible que El Príncipe huya si también es su hijo!? Si no se cuidó que responda",
                                    "en": "How is it possible that The Prince runs away if he is also his son? If he didn’t take care of himself, he has to respond."
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "¿No creen que esa idea del hombre insensible que deja hijos por doquier está pasada de moda?",
                                    "en": "Don't you think that this idea of the insensitive man who leaves children everywhere is outdated?"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Y ahora... ¿quién sigue? Jejeje",
                                    "en": "So… Who 's next? Hehehe"
                                },
                                "correct": false,
                            }
                        ],

                        "required": true,
                        "dValue": "",
                    },
                ]
            },
            {
                "id": 1028,
                "name": "Test-04-LIMITES-Y-AUTOCUIDADO-PARA-PETRA",
                "type": "test",
                "active": false,
                "slug": "test-4-fem",
                "width": 80,
                "minimumApprove": 60,
                "disallowclose": true,
                "title": {
                    "es": "¿Qué sabes de Autocuidado y Límites?",
                    "en": "What do you know about Self-care and limits?"
                },
                "success": {
                    'legend': {
                        "es": '<h2>&iexcl;Felicidades!</h2><p>Eres consciente de que no tienes que traicionar tus ideales ni sacrificar tus sue&ntilde;os, deseos e intereses por complacer a otros. Tus respuestas te identifican con <strong>Arya Stark</strong>.</p>'+
                            '<p>La hermana menor de los Stark en <em>Juego de Tronos</em>, vio c&oacute;mo destrozaron su vida y la de su familia pero no se asust&oacute;. Luch&oacute; para sanar sus heridas y sobrepasar los l&iacute;mites impuestos por otros, estableciendo sus propios l&iacute;mites siendo apenas una ni&ntilde;a. Como Arya, tu has aprendido a cuidarte, defender tus espacios y trazar nuevos l&iacute;mites.&nbsp;</p>'+
                            '<p>&iexcl;Contin&uacute;a as&iacute;! Podr&aacute;s defender tus espacios y ayudar a chicas como Petra a desbloquear la ruta de <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>.</p>',

                        "en": '<p>Congratulations! You are aware that you don&rsquo;t have to betray your ideals or sacrifice your dreams, desires and interests to please others. Your answers identify you with <strong>Arya Stark</strong>.</p>' +
                            '<p>The younger sister of the Starks in <em>Game of Thrones</em>, saw how they destroyed her life and that of her family but she was not scared. She fought to heal her wounds and surpass the limits imposed by others, setting her own limits being just a kid. Like Arya, you have learned to take care of yourself, defend your spaces and draw new limits. </p>' +
                            '<p>Keep it up! You will be able to defend your spaces and help girls like Petra to unlock the <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>&acute;s route.</p>',
                    },
                    "image": "/assets/img/tests/img-test4-limites-fem.png",
                    'epigraph': {
                        "es": 'Arya Stark de <i>Juego de Tronos</i>',
                        "en": 'Arya Stark de <i>Juego de Tronos</i>',
                    },

                },
                "error": {
                    'legend': {
                        "es": '<p>Parece que <em>Patriarcalito</em> te est&aacute; confundiendo y te cuesta identificar qu&eacute; te hace bien y qu&eacute; no, qu&eacute; aporta en tu crecimiento y qu&eacute; te da&ntilde;a, qu&eacute; te permite evolucionar y qu&eacute; te estanca. Cuando eso sucede no eres capaz de decir No, ni puedes decidir conscientemente, porque no sabes lo que quieres y te da miedo quedar mal ante la gente. </p>' +
                            '<p>&iquest;Sabes que algo parecido le sucede a <strong>Blancanieves</strong>? No sabe decir <em>No</em>: se vuelve empleada de los siete enanos sin chistar, recibe comida envenenada de una desconocida. &iexcl;Casi se muere! En este punto un <em>man</em> la besa y ella&hellip; en lugar de reprocharle se enamora. &iquest;Y el consentimiento? Nadie le habl&oacute; de eso. </p>' +
                            '<p>Para que evoluciones de personaje y aprendas a cuidarte sola y desbloquear la ruta de <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>, te invitamos a que vuelvas al inicio y te pienses mejor tus respuestas. &iexcl;Para este juego y para la vida!</p>',

                        "en": '<p>It seems that <em>Patriarcalito</em> is confusing you and it is difficult for you to identify what is good for you and what is not, what contributes to your growth and what harms you, what allows you to evolve and what stagnates you. When that happens you are not able to say No, nor can you consciously decide, because you do not know what you want and you are afraid of looking bad for people.</p>' +
                            '<p>Do you know that something similar happens to <strong>Snow White</strong>? She doesn&rsquo;t know how to say No: she becomes an employee of the seven dwarves without question, receives poisoned food from a stranger. Almost died! At this point a man kisses her and she... instead of reproaching him, she falls in love. And the consent? Nobody told her about it. </p>' +
                            '<p>So that you can evolve character and learn to take care of yourself and unlock the <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>&acute;s route, we invite you to go back to the beginning and think better of your answers. For this game and for life!</p>',
                    },
                    "image": "/assets/img/tests/img-test4-limites-fem-mal.jpg",
                    'epigraph': {
                        "es": 'Blancanieves, de la película <i>Blancanieves y los siete enanos</i>',
                        "en": 'Blancanieves, de la película <i>Blancanieves y los siete enanos</i>',
                    },
                },
                "fields": [
                    {
                        "label": {
                            "es": "Petardo no entiende la negativa de Petra e insiste, ella se marea...",
                            "en": "Petardo doesn’t understand Petras’s refusal and insists, she gets dizzy."
                        },
                        "type": "label",
                    },
                    {
                        "label": {
                            "es": "¿Por qué no ayudaste a Petra a poner límites para salir de esa incómoda situación con Petardo?",
                            "en": "Why didn't you help Petra set limits to get out of that awkward situation with Petardo?"
                        },
                        "slug": "radio-20",
                        "type": "radio",
                        "correct": [2, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "Es una situación manejable. No están solos y ella no corre peligro... !Una canción y ya está!",
                                    "en": "It is a manageable situation. They are not alone and she is not in danger… one song and that’s it!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Petra solo se estaba haciendo la difícil. Es la estrategia de algunas chicas para ligar",
                                    "en": "Petra was only playing hard to get. That’s the strategy of some girls to flirt"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No quería armar un <em>show</em>. Eso sí… ¡Nunca más con este sujeto!",
                                    "en": "She didn’t want to make a big deal out of it. One thing’s for sure… never again with that guy!"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Es un juego y quería hacer el test. En la vida real tomaría otra decisión",
                                    "en": "It is a game and I wanted to do the test. In real life I would take a different decision"
                                },
                                "correct": true,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Petra ayudó a su amiga Violeta a dejar la relación tóxica con Petardo, pero a ella le costó librarse del acoso… ¿Por qué crees que esto sucede?",
                            "en": "Petra helped her friend Violeta to leave the toxic relationship with Petardo, but she had a hard time getting rid of harassment ... Why do you think this happens?"
                        },
                        "slug": "radio-21",
                        "type": "radio",
                        "correct": [0, 1, 2],
                        "options": [
                            {
                                "label": {
                                    "es": "Siempre es más fácil dar consejo ajeno que tomar decisiones propias",
                                    "en": "It is always easier to give advice to others than to make your own decisions"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Es difícil mantenerse firme frente a alguien que insiste en quebrantar tu voluntad",
                                    "en": "It's hard to stand firm in front of someone who insists on breaking your will"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Establecer límites requiere práctica y determinación",
                                    "en": "Setting limits requires practice and determination"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Porque sí quería pero se estaba haciendo la difícil… No sé por qué insisten en lo del acoso",
                                    "en": "Because she really wanted and she was playing hard to get ... I don't know why they insist on the harassment"
                                },
                                "correct": false,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Te parece que…",
                            "en": "Do you think…"
                        },
                        "slug": "radio-21a",
                        "type": "radio",
                        "correct": [0, 1, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "Petardo tiene problemas para establecer límites",
                                    "en": "Petardo has trouble setting limits"
                                },
                                "type": "radio",
                                "slug": "limits-0",
                                "correct": true,
                                "required": false,
                                "dValue": false,
                            },
                            {
                                "label": {
                                    "es": "Petardo es un galán pero Petra es muy complicada y no entiende su humor",
                                    "en": "Petardo is a heartthrob but Petra is very complicated and does not understand his humor"
                                },
                                "type": "radio",
                                "slug": "limits-1",
                                "correct": false,
                                "required": false,
                                "dValue": false,
                            },
                            {
                                "label": {
                                    "es": "Petardo necesita aprender a respetar a las otras personas",
                                    "en": "Petardo needs to learn to respect other people"
                                },
                                "type": "radio",
                                "slug": "limits-2",
                                "correct": true,
                                "required": false,
                                "dValue": false,
                            },
                            {
                                "label": {
                                    "es": "Petardo no sabe que invadir el espacio ajeno física, verbal o emocionalmente, es acoso",
                                    "en": "Petardo doesn't know that Invading someone else’s space, physically, verbally or emotionally, is harassment"
                                },
                                "type": "radio",
                                "slug": "limits-3",
                                "correct": true,
                                "required": false,
                                "dValue": false,
                            },
                        ],

                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Crees que esta situación se puede resolver de otra forma?",
                            "en": "Do you think this situation can be solved in another way?"
                        },
                        "slug": "radio-22",
                        "type": "radio",
                        "correct": [0, 1],
                        "options": [
                            {
                                "label": {
                                    "es": "Sí, enseñándole a Petardo acerca de los límites y el respeto del otro",
                                    "en": "Yes, teaching Petardo about the limits and respect of the other"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Sí, enseñándole a Petra a sobrepasar el miedo y la vergüenza y establecer límites claros",
                                    "en": "Yes, teaching Petra to overcome fear and shame and set clear limits"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Sí, relajándose todo el mundo y disfrutando el momento ¡Que la vida es corta!",
                                    "en": "Yes, everyone should just relax and enjoy the moment. Life is short baby!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No. Por eso tomé este camino",
                                    "en": "No, that’s why I chose this path"
                                },
                                "correct": false,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                ]
            },
            {
                "id": 1035,
                "name": "Test 1 Estereotipos PASCACIO",
                "type": "test",
                "active": false,
                "slug": "test-1-masc",
                "width": 80,
                "minimumApprove": 60,
                "disallowclose": true,
                "title": {
                    "es": "¿Qué es ser hombre?",
                    "en": "What is to be a boy?"
                },
                "success": {
                    'legend': {
                        "es": '<p>&iexcl;Eres El chico Amorfiesta! Tus respuestas te identifican con <strong>Billy Elliot</strong>. El bailar&iacute;n de la pel&iacute;cula de <a href="https://es.wikipedia.org/wiki/Stephen_Daldry" target="_blank">Stephen Daldry</a>, que rompe los estereotipos para cumplir sus sue&ntilde;os. Como &eacute;l, no necesitas encasillar tu masculinidad en los ideales de fuerza y poder asignados a los hombres y as&iacute; te liberas. Aceptas tu vulnerabilidad y muestras tus emociones sin verg&uuml;enza al qu&eacute; dir&aacute;n. Tu visi&oacute;n de la mujer, de otros hombres y dem&aacute;s identidades, se basa en el respeto y la igualdad de derechos.</p>' +
                            '<p>Si con tu ejemplo consigues que las personas respeten la dignidad humana sin importar la edad, g&eacute;nero, nacionalidad o etnia... &iexcl;Est&aacute;s desactivando a <em>Patriarcalito</em> y abriendo rutas de <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>!</p>',
                        "en": '<p>You are The boy of Amorfiesta! Your answers identify you with <strong>Billy Elliot</strong>. The dancing boy in the <a href="https://es.wikipedia.org/wiki/Stephen_Daldry" target="_blank">Stephen Daldry</a> movie, who breaks stereotypes to fulfill his dreams. Like him, you do not need to pigeonhole your masculinity into the ideals of strength and power assigned to men and thus you free yourself. You accept your vulnerability and show your emotions without shame to what they will say. Your vision of women, other men and other identities is based on respect and equal rights.</p>\n' +
                            '<p>If with your example you get other people to respect human dignity regardless of age, sex, nationality or ethnicity, you are deactivating Patriarcalito and opening the Amorfiesta´s route!"</p>'
                    },
                    "image": "/assets/img/tests/img-test1-est-masc.png",
                    'epigraph': {
                        "es": 'Billy Elliot de la película <i>Billy Elliot</i>',
                        "en": 'Billy Elliot de la película <i>Billy Elliot</i>',
                    },

                },
                "error": {
                    'legend': {
                        "es": '<p>¡Parece que te está costando asumir los retos de la masculinidad! Es importante que sepas que puedes hacer mucho daño a otras personas cuando no tienes empatía con sus emociones. Si no corriges esto ahora, después será difícil.</p>\n' +
                            '<p>Un ejemplo de masculinidad mal enfocada lo ofrece el personaje de <strong>Justin</strong> en la serie <em>Por 13 Razones</em><strong>. </strong>&Eacute;l hace p&uacute;blica una foto de un encuentro er&oacute;tico sin medir las consecuencias, causando gran da&ntilde;o a su compa&ntilde;era. Como Petardo, ha tenido privilegios de los que no es consciente y desde esa postura no relaciona derechos con deberes.</p>' +
                            '<p>Para que no repitas los patrones de <i>Patriarcalito </i>y desbloquees la rutas de <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a> en tus relaciones, te invitamos a que vuelvas al inicio y reflexiones sobre tus respuestas. ¡Para este juego y para la vida!</p>',
                        "en": '<p>It seems that you are having trouble taking on the challenges of masculinity! It is important that you know that men can do a lot of harm to other people when they have no empathy with their emotions. If you do not correct this now, then it will be harder.</p>\n' +
                            '<p>An example of badly built masculinity is provided by the character of <strong>Justin</strong> in the tv serie <em>13 Reasons Why Season</em>. He makes public a photo of an erotic encounter without weighing the consequences, causing great damage to his partner. Like Petardo, he has had privileges of which he is not aware and from that position he doesn&rsquo;t relate rights with duties.</p>' +
                            '<p>So that you do not repeat <em>Patriarcalito</em>\'s patterns and unlock the <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta\'s</a> route in your relationships, we invite you to go back to the beginning and reflect on your answers. For this game and for your life!</p>',
                    },
                    "image": "/assets/img/tests/img-test1-est-masc-mal.png",
                    'epigraph': {
                        "es": 'Justin Foley de la serie <i>Por 13 razones</i>',
                        "en": 'Justin Foley de la serie <i>Por 13 razones</i>',
                    },
                },
                "fields": [
                    {
                        "label": {
                            "es": "<em>Petardo se aprovechó de que Violeta estaba ebria para tocarla</em>",
                            "en": "<em>Petardo took advantage of the fact that Violeta was drunk to touch her</em>"
                        },
                        "type": "label",
                        "correct": [0],
                        "options": [],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Por qué apoyas a Petardo?",
                            "en": "Why do you support Petardo?"
                        },
                        "slug": "radio-56",
                        "type": "radio",
                        "correct": [3],
                        "options": [
                            {
                                "label": {
                                    "es": "¡No fue para tanto! Si ella no quería, ¿por qué no se quejó?",
                                    "en": "It’s not a big deal! If she didn’t want to, why didn’t she complain?"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Porque a veces es mejor no discutir por tonterías con los amigos",
                                    "en": "Because sometimes it is better not to discuss nonsense with friends"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Él es hombre y puede hacerlo. Es normal vivir esas experiencias a su edad",
                                    "en": "Because he is a man and he can do it. Also, it's normal to do those things at his age"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Por saber qué sucede si escojo este camino",
                                    "en": "To know what happens if I choose this path"
                                },
                                "correct": true,
                            }
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Piensas que los hombres deben tener relaciones sexuales a temprana edad?",
                            "en": "Do you think men should have sex at an early age?"
                        },
                        "slug": "radio-59",
                        "type": "radio",
                        "correct": [2, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "Sí. Es la mejor forma de aprender: experimentando",
                                    "en": "Yes. Because it is the best way to learn: experimenting"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Sí. Los hombres no tienen nada que perder… ¿Para qué esperar?",
                                    "en": "Yes. Because men have nothing to lose and so... Why wait ?"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No. Los hombres también corren riesgos",
                                    "en": "No. Men also take risks"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "No. Creo que cada quien debe decidir libremente, pero es difícil ir contracorriente",
                                    "en": "No. Deep down I think that everyone should decide freely, but it is difficult to do the opposite of the majority"
                                },
                                "correct": true,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Y las mujeres?",
                            "en": "And women?"
                        },
                        "slug": "radio-61",
                        "type": "radio",
                        "correct": [2, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "Sí. Es la mejor forma de aprender: experimentando",
                                    "en": "Yes. Because it is the best way to learn: experimenting"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Sí. Las mujeres no tienen nada que perder y entonces… ¿Para qué esperar?",
                                    "en": "Yes. Because women have nothing to lose and so... Why wait?"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No. Las mujeres tienen mucho que perder",
                                    "en": "No. Because women have a lot to lose"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "No. Creo que cada quien debe decidir libremente, pero es difícil ir contracorriente",
                                    "en": "No. Deep down I think that everyone should decide freely, but it is difficult to do the opposite of the majority"
                                },
                                "correct": true,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Crees que tener sexo —incluso sin consentimiento— y divulgarlo te hace más <em>cool</em>?",
                            "en": "Do you think that having sex - even without consent - and spreading it makes you cooler?"
                        },
                        "slug": "radio-63",
                        "type": "radio",
                        "correct": [3],
                        "options": [
                            {
                                "label": {
                                    "es": "Sí. Los amigos te admiran y te respetan. ¡Eres EL MAN!",
                                    "en": "Yes. Because friends admire you and respect you. You are THE MAN!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Sí. Las amigas te admiran y te vuelves popular. ¡Eres LA CHICA!",
                                    "en": "Yes. Because friends admire you and you become popular. You are THE WOMAN!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No. Pero a veces hay que exagerar para no quedar mal",
                                    "en": "No. But anyway, sometimes you have to exaggerate to not look like a fool who doesn’t have any chance. Everyone uses their mechanisms!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No. Tener sexo sin consentimiento no es cool, divulgarlo menos",
                                    "en": "No. Having sex without consent is not cool, and even less spreading it"
                                },
                                "correct": true,
                            },


                        ],

                        "required": true,
                        "dValue": "",
                    }
                ]
            },
            {
                "id": 1018,
                "name": "Test 2 Amor romántico PETRA",
                "type": "test",
                "active": false,
                "slug": "test-2-fem",
                "width": 80,
                "minimumApprove": 60,
                "disallowclose": true,
                "title": {
                    "es": "¿Vives el amor romántico?",
                    "en": "Do you live the romantic love?"
                },
                "success": {
                    'legend': {
                        "es": '<p>&iexcl;Felicidades, vives el Amorfiesta! Como <strong>Ana</strong>, la princesa de <em>Frozen</em>, entiendes que si decides unirte a alguien, esa persona debe sumar a tu vida sin quitarte lo que has construido. A pesar de haber cre&iacute;do en el pr&iacute;ncipe azul, el amor eterno y dem&aacute;s mitos e ideas t&oacute;xicas sobre las relaciones, ahora sabes que tener pareja es una decisi&oacute;n personal, consentida, y no un requisito para ser feliz. </p>' +
                            '<p>&iexcl;Sigue as&iacute; y construir&aacute;s tus relaciones con los principios de <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>! Vuelve a la ruta y ayuda a Petra y sus amigas a desbloquear los obst&aacute;culos del amor rom&aacute;ntico.</p>',
                        "en": '<p>Congratulations, you live Amorfiesta! Like <strong>Ana</strong>, the princess from <em>Frozen</em>, you understand that if you decide to join someone, that person must add to your life without taking away what you have built. Despite having believed in prince charming, eternal love and other myths and toxic ideas about relationships, you know that having a partner is a personal decision, consented, and not a requirement to be happy.</p>\n' +
                            '<p>Keep it up and you will build your relationships with the principles of <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>! Get back to the route and help Petra and her friends unlock the obstacles of romantic love.</p>',
                    },
                    "image": "/assets/img/tests/img-test2-amor-fem.png",
                    'epigraph': {
                        "es": 'Ana de la película <i>Frozen</i>',
                        "en": 'Ana de la película <i>Frozen</i>',
                    },

                },
                "error": {
                    'legend': {
                        "es": '<p>Parece que el amor rom&aacute;ntico est&aacute; limitando tus posibilidades. Debes saber que estar en pareja no significa renunciar a tu vida, amistades, familiares, sue&ntilde;os y proyectos. Tu novio o novia no es el centro del universo. &iexcl;No tienes que sacrificarte para estar al lado de alguien!</p>' +
                            '<p>&iquest;Recuerdas a <strong>Bella</strong>? La princesa de Disney que olvida sus sue&ntilde;os para quedarse con la Bestia encerrada en un palacio. Ella se sacrifica, primero por su padre y despu&eacute;s por el amor de un hombre y... &iquest;D&oacute;nde quedan sus propios deseos? Perdidos en los mitos del amor rom&aacute;ntico. </p>' +
                            '<p>Para liberarte de esos cuentos y desbloquear la ruta de <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>, vuelve al inicio y reflexiona: &iquest;C&oacute;mo quieres vivir tus historias de amor?</p>',
                        "en": '<p>It seems that romantic love is limiting your possibilities. You should know that being in a couple does not mean giving up your life, friends, family, dreams and projects. Your boyfriend or girlfriend is not the centre of the universe. You don\'t have to sacrifice anything to be by his/her side!</p>\n' +
                            '<p>Do you remember <strong>Belle</strong>? The Disney princess who forgets her dreams to stay with the Beast locked in a palace. She sacrifices herself, first for her father and then for the love of a man and... where are her own desires? Lost in the myths of romantic love. </p>\n' +
                            '<p>To liberate yourself from them and unlock the <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>&acute;s route, return to the beginning and reflect: How do you want your love stories to be?</p>',
                    },
                    "image": "/assets/img/tests/img-test2-amor-fem-mal.png",
                    'epigraph': {
                        "es": 'Bella de la película La bella y <i>la bestia</i>',
                        "en": 'Bella de la película La bella y <i>la bestia</i>',
                    },
                },
                "fields": [
                    {
                        "label": {
                            "es": "«Así es el amor, a veces complicado ¿no te parece?», le dice emocionada Violeta a Petra.",
                            "en": "“That's the way love is, sometimes complicated. Don't you think?”. Violeta tells Petra excitedly."
                        },
                        "type": "label",
                        "options": [],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Si te parece que el amor es complicado es porque…",
                            "en": "If you think love is complicated, is because…"
                        },
                        "slug": "64",
                        "type": "radio",
                        "correct": [1],
                        "options": [
                            {
                                "label": {
                                    "es": "Es muy difícil encontrar a alguien que cumpla todas tus expectativas",
                                    "en": "It is very difficult to find someone who fits perfectly in your expectations"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Nos enseñaron a esperar un chico lindo que nos cuide y ahora nos dicen que eso crea dependencia. ¡Me confundo con este tema!",
                                    "en": "We were taught to wait for a cute boy to take care of us and now they tell us that this creates dependency.. I just get confused with this topic"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Las mujeres están locas y los hombres no pueden entenderlas",
                                    "en": "Women are crazy and men can't understand them"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Los hombres no saben escuchar",
                                    "en": "Men do not know how to listen"
                                },
                                "correct": false,
                            }
                        ],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Si te parece que el amor no debería ser complicado es porque…",
                            "en": "If you think that love should not be complicated is because..."
                        },
                        "slug": "radio-65",
                        "type": "radio",
                        "correct": [0, 1, 2],
                        "options": [
                            {
                                "label": {
                                    "es": "El amor es una práctica para crecer no para sufrir",
                                    "en": "Love is a practice to grow not to suffer"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "El amor es tan complicado o fácil como cada persona. Si eres desconfiado, violento, no reconoces tus límites y no te quieres, amarás mal. Al contrario, amarás bien",
                                    "en": "Love is as complicated or as easy as each person. If you are distrustful, violent, if you do not recognize your limits and do not love yourself, you will love in a bad way, otherwise you will not"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Porque el amor es una experiencia que te permite reconocer las diferencias y aprender de ellas",
                                    "en": "Because love is an experience that allows us to recognize differences and build from there in freedom"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Porque el amor todo lo puede, todo lo soporta, todo lo espera… ¡Es lo mejor del mundo!",
                                    "en": "Because love can do anything and it endures all, hopes all, and bears all. Love is the best thing in the world!"
                                },
                                "correct": false,
                            }
                        ],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Si te ocurriera lo mismo que a Violeta ¿Qué harías?",
                            "en": "If the same thing that Violeta went through, happened to you, what would you do?"
                        },
                        "slug": "radio-54",
                        "type": "radio",
                        "correct": [0, 3],
                        "options": [
                            {
                                "label": {
                                    "es": "Confronto al chico directamente",
                                    "en": "I would confront the boy directly."
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Trato de alejarme de él para siempre sin decir nada. Solo verlo me causa repugnancia",
                                    "en": "I would try to stay away from that boy forever without saying anything. Just seeing him makes me sick"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No vuelvo a salir de fiesta. Las mujeres corren muchos peligros",
                                    "en": "I would never go out again to any party. Women are in great danger"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Hablo con alguien de confianza para procesar mis emociones y tomar una decisión",
                                    "en": "I would talk to someone close to recognize my feelings and emotions and then make a decision."
                                },
                                "correct": true,
                            }
                        ],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Si pudieras devolver el tiempo ¿Qué le dirías a Violeta?",
                            "en": "If you could go back in time, what would you say to Violeta?"
                        },
                        "slug": "radio-52",
                        "type": "radio",
                        "correct": [0,1,2],
                        "options": [
                            {
                                "label": {
                                    "es": "Amiga, eso no es amor. Ese muchacho abusó de ti y ahora estás en una relación que no te conviene. ¡Tienes que dejarla!",
                                    "en": "Girl, that is not love. That guy abused you and now you are involved in a relationship that doesn’t benefit you. You need to break up with him!"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Deberías hablar de esto con tu madre o con alguien adulto",
                                    "en": "You should talk about this with your mother or with an adult"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "¡Hagamos una confrontación pública con el fulano para que aprenda a respetar!",
                                    "en": "Let’s confront the boy publicly to teach him to respect!"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Nada. No es mi problema. ¡Cada quien vive como quiere!",
                                    "en": "Nothing. It is not my problem, everyone lives as they want!"
                                },
                                "correct": false,
                            }
                        ],
                        "required": false,
                        "dValue": "",
                    }

                ]
            },
            {
                "id": 1040,
                "name": "Test 2 Amor romántico PASCACIO",
                "type": "test",
                "active": false,
                "slug": "test-2-masc",
                "width": 80,
                "minimumApprove": 60,
                "disallowclose": true,
                "title": {
                    "es": "¿Vives el amor romántico?",
                    "en": "Do you live the romantic love?"
                },
                "success": {
                    'legend': {
                        "es": '<h2>&iexcl;Felicidades!</h2><p>Vas por el camino que conduce a construir relaciones libres de violencias, donde afecto, compa&ntilde;erismo y&nbsp; mutuo aprendizaje son prioridad. </p>\n' +
                            '<p>Tus respuestas te identifican con <strong>San</strong> y <strong>Ashikata</strong>, personajes principales de <em>La</em> <em>Princesa Mononoke</em>. Eres consciente de que amar no es convertir a alguien en tu complemento, porque no perteneces a nadie y viceversa. Esto te libera y permite encontrar personas con quienes compartir intereses y crecer, sin perder de vista el propio camino. </p>\n' +
                            '<p>&iexcl;Sigue as&iacute; y construir&aacute;s tus relaciones con los principios del <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>! Vuelve a la ruta y ayuda a Pascacio y sus amigos a desbloquear los obst&aacute;culos del amor rom&aacute;ntico, que les impiden establecer noviazgos saludables.</p>',
                        "en": '<p>Congratulations! You are on the path that leads to building violence-free relationships, where affection, companionship and mutual learning are a priority.</p>\n' +
                            '<p>Your answers identify you with <strong>San</strong> and <strong>Ashikata</strong>, main characters of <em>Princess Mononoke</em>. You are aware that loving is not making someone your complement, because you don&rsquo;t belong to anyone and vice versa. This liberates you and allows you to find people with whom to share interests and grow, without losing sight of your own path. </p>\n' +
                            '<p>Keep it up and you will build your relationships with the principles of <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>! Go back to the route and help Pascacio and his friends unlock the obstacles of romantic love that prevent them from establishing healthy relationships.</p>'
                    },
                    "image": "/assets/img/tests/img-test2-amor-masc.png",
                    'epigraph': {
                        "es": 'San y Ashitaka en la película <i>La princesa Mononoke</i>',
                        "en": 'San y Ashitaka en la película <i>La princesa Mononoke</i>'
                    },

                },
                "error": {
                    'legend': {
                        "es": '<p>Parece que el amor rom&aacute;ntico est&aacute; limitando tus posibilidades. Debes saber que los celos, la desconfianza y el control surgen del miedo y son muestras de abuso, no de amor. Cuando empiezas una relaci&oacute;n bajo estas emociones el cuento de hadas se puede convertir en una aut&eacute;ntica pesadilla. &iexcl;Ten cuidado!</p>\n' +
                            '<p>Una pareja que se crey&oacute; el cuento de hadas es la conformada por <strong>Bella</strong> y <strong>Edward Cullen</strong> en la saga&nbsp; <em>Crep&uacute;sculo</em>, quienes olvidaron que el amor no todo lo puede y que las relaciones deben sumar a tu vida sin eclipsar tu identidad. </p>\n' +
                            '<p>Para no caer en el c&iacute;rculo de violencia del amor rom&aacute;ntico y que puedas ayudar a Pascacio y sus amigos a desbloquear las rutas del <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>, te proponemos que vuelvas a la ruta y reflexiones: &iquest;C&oacute;mo quieres construir tus relaciones?</p>',
                        "en": '<p>It seems that romantic love is limiting your chances of establishing healthy relationships. You should know that jealousy, distrust and control arise from fear and are signs of abuse, not love. When you start a relationship that normalizes these emotions, the fairy tale can become a real nightmare. Be careful!</p>\n' +
                            '<p>A couple who believed the fairy tale is <strong>Bella </strong>and <strong>Edward Cullen</strong> in <em>Twilight</em>, who forgot that love can&rsquo;t do everything and that relationships should add to your life and not take away your identity. </p>\n' +
                            '<p>In order not to fall into the circle of violence of romantic love so you can help Pascacio and his friends to unlock the <a href="https://amorfiesta.com/en/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>&acute;s route, we suggest that you return to the beginning and reflect: How do you want to build your relationships?</p>'
                    },
                    "image": "/assets/img/tests/img-test2-amor-masc-mal.png",
                    'epigraph': {
                        "es": 'Bella Swan y Edward Cullen de la película <i>Crepúsculo</i>',
                        "en": 'Bella Swan y Edward Cullen de la película <i>Crepúsculo</i>'
                    },
                },
                "fields": [
                    {
                        "label": {
                            "es": "«Una chiquita, linda y relajada. Mientras esté conmigo la cuido pa´que no le pase nada». Así describe El Príncipe a su novia.",
                            "en": "“A little girl, quiet and relaxed. While she's with me I take care of her so nothing happens to her”. This is how the Prince describes his girlfriend."
                        },
                        "type": "label",
                        "options": [],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Estás de acuerdo con la forma de amar de El Príncipe?",
                            "en": "Do you agree with the Prince’s way of love?"
                        },
                        "slug": "radio-7",
                        "type": "radio",
                        "correct": [1,2],
                        "options": [
                            {
                                "label": {
                                    "es": "Sí. Él solo quiere cuidar a su chica",
                                    "en": "Yes. He just wants to take care of his girl"
                                },
                                "type": "radio",
                                "slug": "dreamer-2",
                                "correct": false,
                                "required": false,
                                "dValue": false,
                            },
                            {
                                "label": {
                                    "es": "No. Controlar a la pareja no es amor",
                                    "en": "No. Controlling your partner is not love"
                                },
                                "type": "radio",
                                "slug": "dreamer-1",
                                "correct": true,
                                "required": false,
                                "dValue": false,
                            },
                            {
                                "label": {
                                    "es": "<em>El Príncipe</em> no está amando, está obsesionado con la idea de poseer su princesa",
                                    "en": "The Prince is not loving her, he is obsessed with the idea of possessing her princess"
                                },
                                "type": "radio",
                                "slug": "dreamer-1",
                                "correct": true,
                                "required": false,
                                "dValue": false,
                            },
                            {
                                "label": {
                                    "es": "No sé. Creo que esto del amor y las relaciones es muy complicado",
                                    "en": "I don't know. I think this thing about love and relationships is very complicated"
                                },
                                "type": "radio",
                                "slug": "dreamer-1",
                                "correct": false,
                                "required": false,
                                "dValue": false,
                            }
                        ],
                        "required": false,
                        "dValue": "",
                    },

                    {
                        "label": {
                            "es": "Si no estás de acuerdo con El Príncipe… ¿Por qué no lo confrontas?",
                            "en": "If you do not agree with the Prince… Why don’t you confront him?"
                        },
                        "slug": "radio-66",
                        "type": "radio",
                        "correct": [3],
                        "options": [
                            {
                                "label": {
                                    "es": "Porque el man encontró a su media naranja. ¿Pa´qué molestarlo con opiniones personales?",
                                    "en": "Because the man found his better half. Why bother him with personal opinions?"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Pascacio ni novia tiene ¿Qué le va a decir al que sí levanta nenas?",
                                    "en": "Pascacio does not even have a girlfriend. What is he going to say to the one that does hook up often with girls?"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Aunque El Príncipe es un poco exagerado, a las mujeres sí hay que cuidarlas y protegerlas",
                                    "en": "Although the Prince is a bit exaggerated, women must be taken care of and protected"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Porque quería saber qué pasa seleccionando esta opción",
                                    "en": "Because I wanted to know what happens by selecting this option"
                                },
                                "correct": true,
                            }
                        ],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Si crees que el amor y las relaciones son complicadas es porque…",
                            "en": "If you think love is and relationships are complicated it is because…"
                        },
                        "slug": "radio-57",
                        "type": "radio",
                        "correct": [0, 1],
                        "options": [
                            {
                                "label": {
                                    "es": "He crecido en un entorno de conflictos —mentiras, engaños, violencia...—. ¡Eso es complicado!",
                                    "en": "I have grown up in an environment of conflict - lies, deceptions, violence... - That’s complicated!"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Nos enseñaron a ser protectores sabelotodo y ahora nos dicen que eso no es cool. ¡Me confundo con este tema!",
                                    "en": "They taught us to be <em>know-it-all</em> protectors, and now they tell us that is not so cool. I just get confused with this topic"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Las mujeres están locas y los hombres no pueden entenderlas",
                                    "en": "Women are crazy and men can't understand them"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Los hombres no saben escuchar",
                                    "en": "Men do not know how to listen"
                                },
                                "correct": false,
                            }
                        ],
                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Si pudieras devolver el tiempo ¿Qué le dirías a El Príncipe?",
                            "en": "If you could go back in time, what would you say to the Prince?"
                        },
                        "slug": "radio-11",
                        "type": "radio",
                        "correct": [1,2],
                        "options": [
                            {
                                "label": {
                                    "es": "Hermano esa muchacha está muy linda… ¡Ojalá yo me consiga una así en la fiesta!",
                                    "en": "Bro that girl is very pretty ... I hope I get one like that at the party!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "¿Quién te dijo que yo quiero una princesa? A mí me gustan las chicas que saben cuidarse solas",
                                    "en": "Who told you that I want a princess? I like girls who know how to take care of themselves"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Me parece que tienes un problema de amor romántico",
                                    "en": "I think you have a romantic love problem"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Nada. No es mi problema ni es mi novia. ¡Cada quien vive como quiere!",
                                    "en": "Nothing. It is not my problem nor my girlfriend. Everyone lives as they want!"
                                },
                                "correct": false,
                            }
                        ],
                        "required": false,
                        "dValue": "",
                    }

                ]
            },
            {
                "id": 1050,
                "name": "Test-04-LIMITES-Y-AUTOCUIDADO-PARA-PASCACIO",
                "type": "test",
                "active": false,
                "slug": "test-4-masc",
                "width": 80,
                "minimumApprove": 60,
                "disallowclose": true,
                "title": {
                    "es": "¿Qué sabes de Autocuidado y Límites?",
                    "en": "What do you know about Self-care and limits?"
                },
                "success": {
                    'legend': {
                        "es": '<p>&iexcl;Felicidades! Eres capaz de analizar tu situaci&oacute;n, conectar con el entorno y retomar tus sue&ntilde;os a tiempo. </p>' +
                            '<p>Tus respuestas te identifican con <strong>Harry Potter</strong>. A medida que crece, este mago reconoce su poder y l&iacute;mites para no da&ntilde;ar a otros, toma consciencia de su vida y establece c&iacute;rculos de protecci&oacute;n personales que le ayudan a cuidarse. Poco a poco se aleja de quienes le han hecho da&ntilde;o, recupera la confianza en s&iacute; mismo y se apoya en sus seres queridos, ganando credibilidad, bienestar y felicidad&hellip; Como &eacute;l, t&uacute; est&aacute;s aprendiendo a cuidarte, defender tus espacios y trazar nuevos l&iacute;mites. </p>' +
                            '<p>&iexcl;Contin&uacute;a as&iacute;! Nadie te podr&aacute; quitar lo que has aprendido y ayudar&aacute;s a Pascacio a desbloquear la ruta de <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>.</p>',
                        "en": '<p>Congratulations! You are able to analyze your situation, connect with your environment and resume your dreams on time.</p>' +
                            '<p>Your answers identify you with <strong>Harry Potter.</strong> As he grows, this magician recognizes his power and limits to avoid harming others, becomes aware of his life and establishes personal protection circles that help him take care of himself. Little by little he drives away those who have hurt him, he regains his self-confidence and leans on his loved ones, gaining credibility, well-being and happiness ... Like him, you are learning to take care of yourself, defend your spaces and draw new limits.</p>' +
                            '<p>Keep it up! No one will be able to take away what you have learned and you will help Pascacio unlock the <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>&acute;s route.</p>'
                    },
                    "image": "/assets/img/tests/img-test4-limites-masc.png",
                    'epigraph': {
                        "es": 'Harry Potter de la película <i>Harry Potter y la Orden del Fénix</i>',
                        "en": 'Harry Potter de la película <i>Harry Potter y la Orden del Fénix</i>',
                    },

                },
                "error": {
                    'legend': {
                        "es": '<p>Cuando abandonas tus límites y avanzas por la vida sin ser consciente de tu poder personal haces mucho daño. Por ello es importante reflexionar constantemente sobre las capacidades, habilidades y decisiones de vida.</p>' +
                            '<p>¿Conoces a Loki? El dios de la raza de gigantes de hielo posee una capacidad mágica que le permite aumentar todas sus capacidades personales pero se deja llevar por sus obsesiones sin ponerse límites, rompiendo sus vínculos con amistades y familiares, convirtiéndose en uno de los seres más peligrosos del universo.</p>' +
                            '<p>Antes que Loki destruya todo, tú volverás a la ruta para aprender a poner límites y desbloquear la ruta de <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>. ¡En este juego y para la vida!</p>',
                        "en": '<p>When you abandon your limits and move forward in life without being aware of your personal power you do a lot of damage. That&rsquo;s why it is important to constantly reflect on abilities, skills and life decisions.</p>' +
                            '<p>Do you know<strong> Loki</strong>? The god of the ice giants race has a magical ability that allows him to increase all his personal abilities but he lets himself be carried away by his obsessions without setting limits, breaking his ties with friends and family, becoming one of the most dangerous beings in the universe.</p>' +
                            '<p>Before Loki destroys everything, you will return to the route to learn to set limits and unlock the <a href="https://amorfiesta.com/es/manifiesto" target="_blank" data-toggle="tooltip">Amorfiesta</a>&acute;s route. In this game and for life!</p>',
                    },
                    "image": "/assets/img/tests/img-test4-limites-masc-mal.png",
                    'epigraph': {
                        "es": 'Loki de la película <i>Thor: un mundo oscuro</i>',
                        "en": 'Loki de la película <i>Thor: un mundo oscuro</i>',
                    },
                },
                "fields": [
                    {
                        "label": {
                            "es": "Ni Pascacio ni la chica tenían preservativos...",
                            "en": "Neither Pascacio nor the girl had condoms…"
                        },
                        "type": "label",
                    },
                    {
                        "label": {
                            "es": "¿Por qué decidiste tener relaciones sexuales de alto riesgo?",
                            "en": "Why didn't you put limits on the act and decide to have high-risk sex?"
                        },
                        "slug": "radio-43",
                        "type": "radio",
                        "correct": [0,3],
                        // "class":"grouped",
                        "options": [
                            {
                                "label": {
                                    "es": "No sé qué es una relación sexual de alto riesgo",
                                    "en": "I don’t know what is high-risk sex"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Solo se es joven una vez… ¡Pa´qué quiero llegar a la vejez sin experiencias!",
                                    "en": "You are only young once ... Why do I want to get old without any experiences!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Mucha gente tiene sexo sin protección y sigue normal… ¡Todo bien, todo bien!",
                                    "en": "Many people have unprotected relationships and nothing bad happens to them... Everything good, everything good!"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Es un juego y quería hacer el test. En la vida real tomaría otra decisión",
                                    "en": "It is a game and I wanted to take the test. In real life, I would make another decision"
                                },
                                "correct": true,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Pensaste en algún momento las consecuencias de seguir con una relación sexual de alto riesgo?",
                            "en": "Did you ever think about the consequences of continuing with a high-risk sexual relationship?"
                        },
                        "slug": "radio-44",
                        "type": "radio",
                        "correct": [2],
                        // "class":"grouped",
                        "options": [
                            {
                                "label": {
                                    "es": "Sí. Pero quería experimentar",
                                    "en": "Yes, but I wanted to experiment"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No",
                                    "en": "No"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "Con las hormonas y la calentura... ¡Es complicado pensar!",
                                    "en": "With hormones and the heat of the moment, It's hard to think!"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "¿Consecuencias? ¡Después del gusto que venga el susto!",
                                    "en": "Consequences? After the feast comes the reckoning!"
                                },
                                "correct": false,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "Te parece que…",
                            "en": "Do you think that..."
                        },
                        "slug": "radio44a",
                        "type": "radio",
                        "correct": [0,2],
                        "options": [
                            {
                                "label": {
                                    "es": "Pascacio se dejó llevar por la presión del grupo y las bromas acerca de su virginidad",
                                    "en": "Pascacio was carried away by the pressure of the group and jokes about his virginity"
                                },
                                "type": "radio",
                                "slug": "limits-0",
                                "correct": true,
                                "required": false,
                                "dValue": false,
                            },
                            {
                                "label": {
                                    "es": "Pascacio lo hizo bien. ¡Aprovechó la oportunidad!",
                                    "en": "Pascacio did it well. He took this opportunity!"
                                },
                                "type": "radio",
                                "slug": "limits-1",
                                "correct": false,
                                "required": false,
                                "dValue": false,
                            },
                            {
                                "label": {
                                    "es": "Pascacio está aprendiendo y tomó una mala decisión pero hay otras oportunidades para él",
                                    "en": "Pascacio is learning and made a bad decision but there are other opportunities for him"
                                },
                                "type": "radio",
                                "slug": "limits-2",
                                "correct": true,
                                "required": false,
                                "dValue": false,
                            },
                            {
                                "label": {
                                    "es": "Pascacio se está volviendo hombre. ¡Por fin!",
                                    "en": "Pascacio is becoming a man, at last!"
                                },
                                "type": "radio",
                                "slug": "limits-3",
                                "correct": false,
                                "required": false,
                                "dValue": false,
                            },
                        ],

                        "required": false,
                        "dValue": "",
                    },
                    {
                        "label": {
                            "es": "¿Crees que vale la pena poner en riesgo la salud y la vida por un momento de placer?",
                            "en": "Do you think it is worth putting your health and life at risk for a moment of pleasure?"
                        },
                        "slug": "radio-45",
                        "type": "radio",
                        "correct": [0],
                        // "class":"grouped",
                        "options": [
                            {
                                "label": {
                                    "es": "No. Pero esto es un juego, acá se permite todo",
                                    "en": "No, but this is a game, here everything is allowed"
                                },
                                "correct": true,
                            },
                            {
                                "label": {
                                    "es": "Depende del momento y la compañía",
                                    "en": "It depends on the moment and the company"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "¡Claro! ¿Por qué crees que escogí este camino?",
                                    "en": "Of course! Why do you think I chose this path?"
                                },
                                "correct": false,
                            },
                            {
                                "label": {
                                    "es": "No sé. Cuando me suceda te contaré",
                                    "en": "I don’t know. I will tell you when it happens"
                                },
                                "correct": false,
                            },
                        ],

                        "required": true,
                        "dValue": "",
                    },
                ]
            },
            {
                "id": 1053,
                "type": "form",
                "action": "cierrepetra",

                "active": false,
                "slug": "form-cierre-petra",
                "title": {
                    "es": "¡FELICIDADES!",
                    "en": "CONGRATULATIONS!"
                },
                "fields": [
                    {
                        "label": {
                            "es": "Desbloqueaste las rutas de Amorfiesta. Con la ayuda de Andrógino superaste los retos de Patriarcalito y cuentas con nuevas herramientas para construir relaciones libres de violencia y sexualidades responsables.\n" +
                                "\n" +
                                "Si te gustó esta historia y quieres contenidos exclusivos para desafiar a Patriarcalito, contacta con nuestro equipo: contacto@amorfiesta.com",
                            "en": "You unlocked the Amorfiesta routes. With the help of Andrógino you overcame the Patriarcalito´s challenges and now you have new tools to build violence free relationships and responsible sexuality.\n" +
                                "\n" +
                                "If you liked this story and you like to receive exclusive contents to challenge Patriarcalito, please contact us: contacto@amorfiesta.com"
                        },
                        "slug": "textarea0",
                        "type": "textarea",
                        "options": [],
                        "required": false,
                        "dValue": "",
                    },
                ]
            },
            {
                "id": 1054,
                "type": "form",
                "action": "cierrepetrawrong",
                "active": false,
                "slug": "form-cierre-petra-mal",
                "disallowclose": true,
                "title": {
                    "es": "¡VOLVERÁS AL INICIO PARA INTENTARLO DE NUEVO!",
                    "en": "YOU WILL RETURN TO THE BEGINNING TO TRY AGAIN!"
                },
                "fields": [
                    {
                        "label": {
                            "es": "Si has llegado hasta aquí es importante que recuerdes que el autocuidado y el cuidado de los otros es parte integral del Amorfiesta.",
                            "en": "If you have come this far it is important that you remember that self-care and caring for others is an integral part of Amorfiesta."
                        },
                        "type": "label",
                        // "class": "label-special"
                    },
                ]
            },
        ],
        "items": [
//             {
//             "id": 2,
//     "name": "item-1",
//     "type": "video-story",
//     "video-yt": "oHpd1ACuOpA",
//     "src": "/videos/INTRO/3.intro-OUT.mp4",
//     "src_low": "/videos/INTRO/low/3.intro-OUT.mp4",
//     "vtt": "/videos/INTRO/3.intro-OUT.vtt",
//     "poster": "/videos/INTRO/3.intro-OUT.jpg",
//     "loop": false,
//     "next": 3,
//     "zones": [],
//     "interactive": false,
//     "removed": false,
//     "videos": [],
//     "order": 1,
//     "tags": []
// },

            {
                "id": 2001,
                "name": "intronew",
                "type": "video-story",
                "video-yt-en": "ONMt3rqwqZU",
                "video-yt": "4L0bMuRmMAY",
                "src": "/videos/PETRA/petra-E2-op1.mp4",
                "src_low": "/videos/PETRA/petra-E2-op1.mp4",
                "vtt": "",
                "poster": "/videos/PETRA/petra-E2-op1.jpg",
                "choices": 1,
                "next": 2003,
                "interactive": false,
                "loop": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": [],
                "captions": [],
            },
            {

                "id": 2003,
                "name": "Imagen musica",
                "type": "video-story",
                "loop": true,
                "video-yt-en": "dEXVI1uGITg",
                "video-yt": "kS67FtKIdKI",
                "src": null,
                "src_low": null,
                "captions": [],
                "zones": [
                    {
                        "id": 1,
                        "title": "continue",
                        "type": "continue",
                        "modal": false,
                        "link": "https://",
                        "top": 70,
                        "left": 53,
                        "width": 17,
                        "height": 3,
                        "next":1008
                    },
                ],
                "vtt": null,
                "poster": null,
                "choices": null,
                "next": 1008,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },
            {
                "id": 2002,
                "name": "intronew",
                "type": "video-story",
                "video-yt": "1jjJpePfNGo",
                "video-yt-en": "toBVU2rXGL8",
                "src": "/videos/PETRA/petra-E2-op1.mp4",
                "src_low": "/videos/PETRA/petra-E2-op1.mp4",
                "vtt": "",
                "poster": "/videos/PETRA/petra-E2-op1.jpg",
                "choices": 1,
                "next": 1009,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [
                    {
                        "id": 1,
                        "title": "continue",
                        "type": "continue",
                        "modal": false,
                        "link": "https://",
                        "top": 70,
                        "left": 53,
                        "width": 17,
                        "height": 3,
                        "next":1009
                    },
            ],
                "tags": []
            },
            {

                "id": 1,
                "name": "Entrar o Saltar intro",
                "type": "image-intro",
                "video-yt": null,
                "loop": false,
                "src": "/videos/INTRO/2.intro-img.jpg",
                "src_low": null,
                "audio": "/videos/INTRO/2.intro-audio-LOOP.mp3",
                "captions": [
                    {
                        "id": 1,
                        "text": "ENTER",
                        "top": 83,
                        "left": 45,
                        "width": 15,
                        "height": 7,
                    },
                    {
                        "id": 2,
                        "text": "SKIP INTRO",
                        "top": 83,
                        "left": 78,
                        "width": 180,
                        "height": 7,
                    },

                ],
                "zones": [
                    {
                        "id": 1,
                        "title": "saltar intro",
                        "type": "redirect",
                        "modal": false,
                        "link": "https://",
                        "top": 85,
                        "left": 76,
                        "width": 15,
                        "height": 7,
                        "next": 2001,
                        "style": {clipPath: 'polygon(6% 27%, 87% 24%, 91% 81%, 10% 76%)'},
                    },
                    {
                        "id": 2,
                        "title": "entrar",
                        "type": "continue",
                        "modal": false,
                        "link": "https://",
                        "top": 57,
                        "left": 35,
                        "width": 28,
                        "height": 13,
                        "next": 2,
                        "style": {clipPath: 'polygon(10% 27%, 87% 24%, 91% 75%, 16% 73%)'},
                    }
                ],
                "vtt": null,
                "poster": null,
                "choices": null,
                "next": 2,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },
            {
                "id": 2,
                "name": "item-1",
                "type": "video-story",
                "video-yt": "oHpd1ACuOpA",
                "src": "/videos/INTRO/3.intro-OUT.mp4",
                "src_low": "/videos/INTRO/low/3.intro-OUT.mp4",
                "vtt": "/videos/INTRO/3.intro-OUT.vtt",
                "poster": "/videos/INTRO/3.intro-OUT.jpg",
                "loop": false,
                "choices": 1,
                "next": 3,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },
            {
                "id": 3,
                "name": "item-1",
                "type": "video-out-story",
                "video-yt": "lcmjkgjmhT4",
                "src": "/videos/INTRO/4.eleccion-IN.mp4",
                "src_low": "/videos/INTRO/low/4.eleccion-IN.mp4",
                "vtt": '/videos/INTRO/4.eleccion-IN.vtt',
                "poster": "/videos/INTRO/4.eleccion-IN.jpg",
                "choices": 1,
                "loop": false,
                "next": 4,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": [],
                "zones": []
            },
            {
                "id": 4,
                "name": "elegir-1",
                "type": "image-intro",
                "video-yt": null,
                "src": "/videos/INTRO/5.eleccion.jpg",
                "src_low": null,
                "audio": null,
                "loop": false,
                "next": 2001,
                "videos": [],
                "order": 1,
                "interactive": true,
                "captions": [
                    {
                        "id": 1,
                        "text": "Go back in time",
                        "top": 83,
                        "left": 18,
                        "width": 230,
                        "height": 7,
                    },
                    {
                        "id": 2,
                        "text": "Continue this story",
                        "top": 83,
                        "left": 65,
                        "width": 275,
                        "height": 7,
                    },

                ],
                "zones": [
                    {
                        "id": 1,
                        "title": "pibita",
                        "type": "continue",
                        "modal": false,
                        "link": "https://",
                        "top": 0,
                        "left": 0,
                        "width": 50,
                        "height": 100,
                        "next":2001
                    },
                    {
                        "id": 2,
                        "title": "pibito",
                        "type": "redirect",
                        "modal": false,
                        "link": "https://",
                        "top": 0,
                        "left": 50,
                        "width": 50,
                        "height": 100,
                        "next": 6
                    }
                ]
            },
            {
                "id": 5,
                "name": "video-intro-elegir-personajes",
                "type": "video-story",
                "video-yt": "0WqAtsL8dAk",
                "src": "/videos/INTRO/6.intro-OP1.mp4",
                "src_low": "/videos/INTRO/low/6.intro-OP1.mp4",
                "vtt": null,
                "poster": "/videos/INTRO/8.eleccion-personaje-LOOP.jpg",
                "loop": false,
                "choices": 1,
                "next": 7,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 6,
                "name": "opcion2-video-embarazo-volves-a-empezar",
                "type": "video-story",
                "video-yt": "PGGM4SwWXck",
                "src": "/videos/INTRO/7.intro-OP2.mp4",
                "src_low": "/videos/INTRO/low/7.intro-OP2.mp4",
                "vtt": "/videos/INTRO/7.intro-OP2.vtt",
                "poster": "/videos/INTRO/7.intro-OP2.jpg",
                "loop": false,
                "choices": 1,
                "next": 1010,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 1008,
                "name": "form login",
                "type": "test-",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "/videos/INTRO/8.eleccion-personaje-LOOP.jpg",
                "choices": 1,
                "next": 5,
                // "previous": 6,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },{
                "id": 1009,
                "name": "form login",
                "type": "test-",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "/videos/INTRO/8.eleccion-personaje-LOOP.jpg",
                "choices": 1,
                "next": 1010,
                "previous": 4,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 1010,
                "name": "Test-00-embarazo-adolescente",
                "type": "test-escena-0",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "/videos/INTRO/8.eleccion-personaje-LOOP.jpg",
                "choices": 1,
                "next": 2001,
                "previous": 2001,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 7,
                "name": "item-1",
                "type": "video-choose-character",
                "video-yt": "EZOzOBuAe_c",
                "src": "/videos/INTRO/6.intro-OP1.mp4",
                "src_low": "/videos/INTRO/low/8.eleccion-personaje-LOOP.mp4",
                "poster": "/videos/INTRO/8.eleccion-personaje-LOOP.jpg",
                "loop": true,
                "zones": [
                    {
                        "id": 1,
                        "title": "pibita",
                        "type": "continue",
                        "modal": false,
                        "link": "https://",
                        "top": 0,
                        "left": 0,
                        "width": 50,
                        "height": 100,
                        "next": 8,
                        "styles": {},
                    },
                    {
                        "id": 2,
                        "title": "pibito",
                        "type": "redirect",
                        "modal": false,
                        "link": "https://",
                        "top": 0,
                        "left": 50,
                        "width": 50,
                        "height": 100,
                        "next": 30,
                        "styles": {},
                    }

                ],
                "choices": 1,
                "next": 8,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },
            {
                "id": 8,
                "name": "petra-00-INICIO",
                "type": "video-inicio-petra",
                "video-yt": "FqjeWziSl-g",
                "src": "/videos/PETRA/petra-00-INICIO.mp4",
                "src_low": "/videos/PETRA/low/petra-00-INICIO.mp4",
                "vtt": "/videos/PETRA/petra-00-INICIO.vtt",
                "poster": "/videos/PETRA/petra-00-INICIO.jpg",
                "loop": false,
                "zones": [],
                "choices": 1,
                "next": 9,
                "interactive": false,
                "removed": false,
                "checkpoint": true,
                "duration": [17.09],
                "videos": [],
                "order": 1,
                "tags": []
            },
            {
                "id": 9,
                "name": "petra-E1",
                "type": "video-E1",
                "video-yt": "Oau_6OrOKqA",
                "src": "/videos/PETRA/petra-E1.mp4",
                "src_low": "/videos/PETRA/low/petra-E1.mp4",
                "vtt": "/videos/PETRA/petra-E1.vtt",
                "poster": "/videos/PETRA/petra-E1.jpg",
                "loop": false,
                "zones": [],
                "choices": 12,
                "next": 10,
                "interactive": false,
                "removed": false,
                "videos": [],
                "checkpoint": true,
                "duration": [134.049, 75.535],
                "order": 1,
                "tags": [
                    // {
                    //     "id": 1,
                    //     "in": "40",
                    //     "out": "45",
                    //     "x": 70,
                    //     "y": 50,
                    //     "link": "eW36RovzMqU",
                    //     "type": "video",
                    //     // "link": "/images/material-comic-5-limites-y-autocuidado.jpg",
                    //     // "type":"image",
                    // },
                    {
                        "id": 1,
                        "in": "9",
                        "out": "15",
                        "x": 10,
                        "y": 50,
                        "link": "eW36RovzMqU",
                        "type": "video",
                        // "link": "/images/material-comic-5-limites-y-autocuidado.jpg",
                        // "type":"image",
                    }
                ]
            },
            {
                "id": 10,
                "name": "item-1",
                "type": "choice-escena-1",
                "video-yt": "4_NEH0dKNPM",
                "src": "/videos/PETRA/petra-E1-decision.mp4",
                "src_low": "/videos/low/PETRA/petra-E1-decision.mp4",
                "vtt": null,
                "poster": "/videos/PETRA/petra-E1-decision-1.jpg",
                "choices": {
                    left: 11,
                    right: 12,
                },
                "next": 11,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },

            {
                "id": 11,
                "name": "petra-e1-opcion-1",
                "type": "video-story",
                "video-yt": "qYJmGv1XOqQ",
                "src": "/videos/PETRA/petra-E1-op1.mp4",
                "src_low": "/videos/PETRA/petra-E1-op1.mp4",
                "vtt": "/videos/PETRA/petra-E1-op1.vtt",
                "poster": "/videos/PETRA/petra-E1-op1.jpg",
                "loop": false,
                "choices": 1,
                "next": 1013,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },

            {
                "id": 12,
                "name": "petra-e1-opcion-2",
                "type": "video-story",
                "video-yt": "gCQQSjZc01U",
                "src": "/videos/PETRA/petra-E1-op2.mp4",
                "src_low": "/videos/PETRA/low/petra-E1-op2.mp4",
                "vtt": "/videos/PETRA/petra-E1-op2.vtt",
                "poster": "/videos/PETRA/petra-E1-op2.jpg",
                "loop": false,
                "choices": 1,
                "next": 14,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": [
                    {
                        "id": 1,
                        "in": "40",
                        "out": "47",
                        "x": 70,
                        "y": 50,
                        "link": "/assets/img/comics/1-estereotipos.jpg",
                        "link_en": "/assets/img/comics/en-1-stereotypes.jpg",
                        "type": "image",
                    }
                ]
            },
            {
                "id": 1013,
                "name": "test-1-fem",
                "type": "test-escena-2",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "/videos/PETRA/petra-E1-op2.jpg",
                "choices": 1,
                "next": 14,
                "previous": 10,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 14,
                "name": "petra-E2",
                "type": "video-story",
                "video-yt": "FsSSMQ5EuV0",
                "src": "/videos/PETRA/petra-E2.mp4",
                "src_low": "/videos/PETRA/petra-E2.mp4",
                "vtt": "/videos/PETRA/petra-E2.vtt",
                "poster": "/videos/PETRA/petra-E2.jpg",
                "loop": false,
                "choices": 17,
                "next": 15,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "checkpoint": true,
                "duration": [70.055, 53.615],
                "order": 1,
                "tags": [
                    {
                        "id": 1,
                        "in": "31",
                        "out": "38",
                        "x": 70,
                        "y": 50,
                        "link": "en9HsMOUKdA",
                        "type": "video",
                    }
                ]
            },
            {
                "id": 15,
                "name": "item-1",
                "type": "choice-escena-2-decision",
                "video-yt": "jIYv2CG3fwM",
                "src": "/videos/PETRA/petra-E2-decision.mp4",
                "src_low": "/videos/low/PETRA/petra-E2-decision.mp4",
                "vtt": null,
                "poster": "/videos/PETRA/petra-E2-decision.jpg",
                "choices": {
                    left: 16,
                    right: 17,
                },
                "next": 16,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },

            {
                "id": 16,
                "name": "petra-E2-op1",
                "type": "video-story",
                "video-yt": "-OFU2rn-LAE",
                "src": "/videos/PETRA/petra-E2-op1.mp4",
                "src_low": "/videos/PETRA/petra-E2-op1.mp4",
                "vtt": "/videos/PETRA/petra-E2-op1.vtt",
                "poster": "/videos/PETRA/petra-E2-op1.jpg",
                "choices": 1,
                "next": 1018,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 17,
                "name": "petra-E2-op2",
                "type": "video-story",
                "video-yt": "ZbpO6oTqdqU",
                "src": "/videos/PETRA/petra-E2-op2.mp4",
                "src_low": "/videos/PETRA/petra-E2-op2.mp4",
                "vtt": "/videos/PETRA/petra-E2-op2.vtt",
                "poster": "/videos/PETRA/petra-E2-op2.jpg",
                "choices": 1,
                "next": 19,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": [
                    {
                        "id": 1,
                        "in": "33",
                        "out": "39",
                        "x": 70,
                        "y": 50,
                        "link": "/assets/img/comics/2-mitos-del-amor-romantico.jpg",
                        "link_en": "/assets/img/comics/en-2-myths-of-romantic-love.jpg",
                        "type": "image",
                    }
                ]
            },
            {
                "id": 1018,
                "name": "test-02-MITOS-DEL-AMOR-ROMANTICO-PARA-PETRA",
                "type": "test-story",
                "video-yt": "",
                "src": "",
                "src_low": "",
                "vtt": "",
                "poster": "",
                "choices": 1,
                "next": 19,
                "previous": 15,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 19,
                "name": "petra-E3",
                "type": "video-story",
                "video-yt": "Z3hFAJ3555o",
                "src": "/videos/PETRA/petra-E3.mp4",
                "src_low": "/videos/PETRA/petra-E3.mp4",
                "vtt": "/videos/PETRA/petra-E3.vtt",
                "poster": "/videos/PETRA/petra-E3.jpg",
                "choices": 22,
                "next": 20,
                "interactive": false,
                "removed": false,
                "videos": [],
                "checkpoint": true,
                "duration": [61.092, 68.429206],
                "order": 1,
                "zones": [],
                "tags": [
                    {
                        "id": 1,
                        "in": "40",
                        "out": "46",
                        "x": 70,
                        "y": 50,
                        "link": "4smmPIbmmPg",
                        "type": "video",
                    }
                ]
            },
            {
                "id": 20,
                "name": "petra-E3-decision",
                "type": "choice-E3-decision",
                "video-yt": "7IsSx_1FTM8",
                "src": "/videos/PETRA/petra-E3-decision.mp4",
                "src_low": "/videos/low/PETRA/petra-E3-decision.mp4",
                "vtt": null,
                "poster": "/videos/PETRA/petra-E3-decision-0.jpg",
                "choices": {
                    left: 21,
                    right: 22,
                },
                "next": 21,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 21,
                "name": "petra-E3-op1",
                "type": "video-story",
                "video-yt": "WTtk483Ooj8",
                "src": "/videos/PETRA/petra-E3-op1.mp4",
                "src_low": "/videos/PETRA/petra-E3-op1.mp4",
                "vtt": "/videos/PETRA/petra-E3-op1.vtt",
                "poster": "/videos/PETRA/petra-E3-op1.jpg",
                "choices": 1,
                "next": 1023,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 22,
                "name": "petra-E3-op2",
                "type": "video-story",
                "video-yt": "HgtqxbwB_JE",
                "src": "/videos/PETRA/petra-E3-op2.mp4",
                "src_low": "/videos/PETRA/petra-E3-op2.mp4",
                "vtt": "/videos/PETRA/petra-E3-op2.vtt",
                "poster": "/videos/PETRA/petra-E3-op2.jpg",
                "choices": 1,
                "next": 24,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": [
                    {
                        "id": 1,
                        "in": "2",
                        "out": "8",
                        "x": 70,
                        "y": 50,
                        "link": "/assets/img/comics/3-acoso-callejero.jpg",
                        "link_en": "/assets/img/comics/en-3-street-harrasment.jpg",
                        "type": "image",
                    }
                ]
            },
            {
                "id": 1023,
                "name": "Test-03-ACOSO-CALLEJERO",
                "type": "test-escena-3",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "/videos/PETRA/petra-E3-op2.jpg",
                "choices": 1,
                "next": 24,
                "previous": 20,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 24,
                "name": "petra-E4",
                "type": "video-story",
                "video-yt": "cAh4o9L1vQg",
                "src": "/videos/PETRA/petra-E4.mp4",
                "src_low": "/videos/PETRA/petra-E4.mp4",
                "vtt": "/videos/PETRA/petra-E4.vtt",
                "poster": "/videos/PETRA/petra-E4.jpg",
                "choices": 27,
                "next": 25,
                "interactive": false,
                "removed": false,
                "videos": [],
                "checkpoint": true,
                "duration": [60.047, 22.059],
                "order": 1,
                "zones": [],
                "tags": [
                    {
                        "id": 1,
                        "in": "6",
                        "out": "12",
                        "x": 70,
                        "y": 50,
                        "link": "NvAHbOr2kwE",
                        "type": "video",
                    },
                    {
                        "id": 2,
                        "in": "35",
                        "out": "41",
                        "x": 70,
                        "y": 50,
                        "link": "/assets/img/comics/5-limites-y-autocuidado.jpg",
                        "link_en": "/assets/img/comics/en-5-limits-and-self-care.jpg",
                        "type": "image",
                    }
                ]
            },
            {
                "id": 25,
                "name": "petra-E4-decision",
                "type": "choice-E4-decision",
                "video-yt": "fNyO05ONk6A",
                "src": "/videos/PETRA/petra-E4-decision.mp4",
                "src_low": "/videos/low/PETRA/petra-E4-decision.mp4",
                "vtt": null,
                "poster": "/videos/PETRA/petra-E4-decision-0.jpg",
                "choices": {
                    left: 26,
                    right: 27,
                },
                "next": 26,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 26,
                "name": "petra-E4-op1",
                "type": "video-story",
                "video-yt": "AMDgRYWiSIE",
                "src": "/videos/PETRA/petra-E4-op1.mp4",
                "src_low": "/videos/PETRA/petra-E4-op1.mp4",
                "vtt": "/videos/PETRA/petra-E4-op1.vtt",
                "poster": "/videos/PETRA/petra-E4-op1.jpg",
                "choices": 1,
                "next": 1028,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 27,
                "name": "petra-E4-op2",
                "type": "video-story",
                "video-yt": "sP1e5wjNedM",
                "src": "/videos/PETRA/petra-E4-op2.mp4",
                "src_low": "/videos/PETRA/petra-E4-op2.mp4",
                "vtt": "/videos/PETRA/petra-E4-op2.vtt",
                "poster": "/videos/PETRA/petra-E4-op2.jpg",
                "choices": 1,
                "next": 29,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 1028,
                "name": "Test-04-LIMITES-Y-AUTOCUIDADO-PARA-PETRA",
                "type": "test-escena-4",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "",
                "choices": 1,
                "next": 29,
                "previous": 25,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 29,
                "name": "petra-E5",
                "type": "video-story",
                "video-yt": "2jFRq4zzvCw",
                "src": "/videos/PETRA/petra-E5.mp4",
                "src_low": "/videos/PETRA/petra-E5.mp4",
                "vtt": "/videos/PETRA/petra-E5.vtt",
                "poster": "/videos/PETRA/petra-E5.jpg",
                "next": 291,
                "interactive": false,
                "removed": false,
                "checkpoint": true,
                "duration": [63.019],
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": [
                    {
                    "id": 1,
                    "in": "10",
                    "out": "16",
                    "x": 70,
                    "y": 50,
                        "link": "/assets/img/comics/6-educacion-sexual.jpg",
                        "link_en": "/assets/img/comics/en-6-sexual-education-and-prevention.jpg",
                        "type": "image",
                    },
                    {
                        "id": 2,
                        "in": "39",
                        "out": "45",
                        "x": 70,
                        "y": 50,
                        "link": "RXPyN7aciFI",
                        "type": "video",
                    }]
            },
            {
                "id": 290,
                "name": "elegir-1",
                "type": "image-intro",
                "video-yt": null,
                "src": "/videos/PASCACIO/pascacio-E5-decision.jpg",
                "src_low": null,
                "audio": null,
                "loop": false,
                "next": 1054,
                "videos": [],
                "order": 1,
                "interactive": true,
                "zones": [
                    {
                        "id": 1,
                        "title": "pibita",
                        "type": "continue",
                        "modal": false,
                        "link": "https://",
                        "top": 0,
                        "left": 0,
                        "width": 50,
                        "height": 100,
                        "next": 1054
                    },
                    {
                        "id": 2,
                        "title": "pibito",
                        "type": "redirect",
                        "modal": false,
                        "link": "https://",
                        "top": 0,
                        "left": 50,
                        "width": 50,
                        "height": 100,
                        "next": 1053
                    }
                ]
            },
            {
                "id": 291,
                "name": "elegir-1",
                "type": "image-intro",
                "video-yt": null,
                "src": "/videos/PETRA/petra-E5-decision.jpg",
                "src_low": null,
                "audio": null,
                "loop": false,
                "next": 1054,
                "videos": [],
                "order": 1,
                "interactive": true,
                "zones": [
                    {
                        "id": 1,
                        "title": "pibita",
                        "type": "continue",
                        "modal": false,
                        "link": "https://",
                        "top": 0,
                        "left": 0,
                        "width": 50,
                        "height": 100,
                        "next": 1054
                    },
                    {
                        "id": 2,
                        "title": "pibito",
                        "type": "redirect",
                        "modal": false,
                        "link": "https://",
                        "top": 0,
                        "left": 50,
                        "width": 50,
                        "height": 100,
                        "next": 1053
                    }
                ]
            },
            {
                "id": 1053,
                "name": "form-petra-E5",
                "type": "test-cierre-petra",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "",
                "choices": 1,
                "next": null,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 1054,
                "name": "test-petra-E5-cierre-mal",
                "type": "test-cierre-petra-mal",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "",
                "choices": 1,
                "next": null,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 30,
                "name": "pascacio-00-INICIO",
                "type": "video-inicio-pascacio",
                "video-yt": "NoExv80Syk4",
                "src": "/videos/PASCACIO/pascacio-00-INICIO.mp4",
                "src_low": "/videos/PASCACIO/pascacio-00-INICIO.mp4",
                "vtt": "/videos/PASCACIO/pascacio-00-INICIO.vtt",
                "poster": "/videos/PASCACIO/pascacio-00-INICIO.jpg",
                "loop": false,
                "zones": [],
                "choices": 1,
                "next": 31,
                "interactive": false,
                "removed": false,
                "videos": [],
                "checkpoint": true,
                "duration": [17.903],
                "order": 1,
                "tags": []
            },
            {
                "id": 31,
                "name": "pascacio-E1",
                "type": "video-E1-pascacio",
                "video-yt": "egPW5dG4U5I",
                "src": "/videos/PASCACIO/pascacio-E1.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E1.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E1.vtt",
                "poster": "/videos/PASCACIO/pascacio-E1.jpg",
                "loop": false,
                "zones": [],
                "choices": 1,
                "next": 32,
                "interactive": false,
                "removed": false,
                "videos": [],
                "checkpoint": true,
                "duration": [47.624127, 50.109],
                "order": 1,
                "tags": [
                    {
                        "id": 1,
                        "in": "11",
                        "out": "16",
                        "x": 70,
                        "y": 50,
                        "link": "eW36RovzMqU",
                        "type": "video",
                    },
                    {
                        "id": 2,
                        "in": "31",
                        "out": "34",
                        "x": 70,
                        "y": 50,
                        "link": "/assets/img/comics/1-estereotipos.jpg",
                        "link_en": "/assets/img/comics/en-1-stereotypes.jpg",
                        "type": "image",
                    }
                ]
            },
            {
                "id": 32,
                "name": "pascacio-E1-decision",
                "type": "choice-escena-1",
                "video-yt": "XymlHJu2KnM",
                "src": "/videos/PASCACIO/pascacio-E1-decision.mp4",
                "src_low": "/videos/low/PASCACIO/pascacio-E1-decision.mp4",
                "vtt": null,
                "poster": "/videos/PASCACIO/pascacio-E1-decision-1.jpg",
                "choices": {
                    left: 33,
                    right: 34,
                },
                "next": 33,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },

            {
                "id": 33,
                "name": "pascacio-E1-op1",
                "type": "video-story",
                "video-yt": "iQyQv5bpcn8",
                "src": "/videos/PASCACIO/pascacio-E1-op1.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E1-op1.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E1-op1.vtt",
                "poster": "/videos/PASCACIO/pascacio-E1-op1.jpg",
                "loop": false,
                "choices": 1,
                "next": 1035,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },

            {
                "id": 34,
                "name": "pascacio-E1-op2",
                "type": "video-story",
                "video-yt": "1hiuuByB4nA",
                "src": "/videos/PASCACIO/pascacio-E1-op2.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E1-op2.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E1-op2.vtt",
                "poster": "/videos/PASCACIO/pascacio-E1-op2.jpg",
                "loop": false,
                "choices": 1,
                "next": 36,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },
            {
                "id": 1035,
                "name": "Test-01-Estereotipos-masculinos",
                "type": "test-escena-1",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "/videos/PASCACIO/pascacio-E1-op2.jpg",
                "choices": 1,
                "next": 36,
                "previous": 32,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 36,
                "name": "pascacio-E2",
                "type": "video-E2-pascacio",
                "video-yt": "v2mEPMlNCgc",
                "src": "/videos/PASCACIO/pascacio-E2.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E2.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E2.vtt",
                "poster": "/videos/PASCACIO/pascacio-E2.jpg",
                "loop": false,
                "zones": [],
                "choices": 1,
                "next": 37,
                "interactive": false,
                "removed": false,
                "videos": [],
                "checkpoint": true,
                "duration": [69.73, 35.062132],
                "order": 1,
                "tags": [
                    {
                        "id": 1,
                        "in": "22",
                        "out": "33",
                        "x": 70,
                        "y": 50,
                        "link": "en9HsMOUKdA",
                        "type": "video",
                    },
                    {
                        "id": 2,
                        "in": "41",
                        "out": "47",
                        "x": 70,
                        "y": 50,
                        "link": "/assets/img/comics/2-mitos-del-amor-romantico.jpg",
                        "link_en": "/assets/img/comics/en-2-myths-of-romantic-love.jpg",
                        "type": "image",
                    }
                ]
            },
            {
                "id": 37,
                "name": "pascacio-E2-decision",
                "type": "choice-escena-2",
                "video-yt": "Vpu6QODQMnk",
                "src": "/videos/PASCACIO/pascacio-E2-decision.mp4",
                "src_low": "/videos/low/PASCACIO/pascacio-E2-decision.mp4",
                "vtt": null,
                "poster": "/videos/PASCACIO/pascacio-E2-decision-1.jpg",
                "choices": {
                    left: 38,
                    right: 39,
                },
                "next": 38,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },

            {
                "id": 38,
                "name": "pascacio-E2-op1",
                "type": "video-story",
                "video-yt": "O2fQwRBYoOE",
                "src": "/videos/PASCACIO/pascacio-E2-op1.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E2-op1.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E2-op1.vtt",
                "poster": "/videos/PASCACIO/pascacio-E2-op1.jpg",
                "loop": false,
                "choices": 1,
                "next": 1040,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },

            {
                "id": 39,
                "name": "pascacio-E2-op2",
                "type": "video-story",
                "video-yt": "UVWV-ZWXGBQ",
                "src": "/videos/PASCACIO/pascacio-E2-op2.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E2-op2.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E2-op2.vtt",
                "poster": "/videos/PASCACIO/pascacio-E2-op2.jpg",
                "loop": false,
                "choices": 1,
                "next": 41,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },
            {
                "id": 1040,
                "name": "test-02-MITOS-DEL-AMOR-ROMANTICO-PARA-PASCACIO",
                "type": "test-escena2",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "/videos/PASCACIO/pascacio-E2-op2.jpg",
                "choices": 1,
                "next": 41,
                "previous": 37,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 41,
                "name": "pascacio-E3",
                "type": "video-E3-pascacio",
                "video-yt": "0vC7fANdyyw",
                "src": "/videos/PASCACIO/pascacio-E3.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E3.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E3.vtt",
                "poster": "/videos/PASCACIO/pascacio-E3.jpg",
                "loop": false,
                "zones": [],
                "choices": 1,
                "next": 42,
                "interactive": false,
                "removed": false,
                "videos": [],
                "checkpoint": true,
                "duration": [66.061, 54.056054],
                "order": 1,
                "tags": [
                    {
                        "id": 1,
                        "in": "6",
                        "out": "14",
                        "x": 70,
                        "y": 50,
                        "link": "mbZY-SeTHqw",
                        "type": "video",
                    }
                ]
            },
            {
                "id": 42,
                "name": "pascacio-E3-decision",
                "type": "choice-escena-3",
                "video-yt": "ru5rPR8N5Ks",
                "src": "/videos/PASCACIO/pascacio-E3-decision.mp4",
                "src_low": "/videos/low/PASCACIO/pascacio-E3-decision.mp4",
                "vtt": null,
                "poster": "/videos/PASCACIO/pascacio-E3-decision-1.jpg",
                "choices": {
                    left: 43,
                    right: 44,
                },
                "next": 43,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },

            {
                "id": 43,
                "name": "pascacio-E3-op1",
                "type": "video-story",
                "video-yt": "pS12RWoptQ8",
                "src": "/videos/PASCACIO/pascacio-E3-op1.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E3-op1.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E3-op1.vtt",
                "poster": "/videos/PASCACIO/pascacio-E3-op1.jpg",
                "loop": false,
                "choices": 1,
                "next": 1045,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },

            {
                "id": 44,
                "name": "pascacio-E3-op2",
                "type": "video-story",
                "video-yt": "fR--FPXaciw",
                "src": "/videos/PASCACIO/pascacio-E3-op2.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E3-op2.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E3-op2.vtt",
                "poster": "/videos/PASCACIO/pascacio-E3-op2.jpg",
                "loop": false,
                "choices": 1,
                "next": 46,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": [
                    {
                        "id": 1,
                        "in": "38",
                        "out": "46",
                        "x": 70,
                        "y": 50,
                        "link": "/assets/img/comics/4-nuevas-masculinidades.jpg",
                        "link_en": "/assets/img/comics/en-4-new-masculinities.jpg",
                        "type": "image",
                    }
                ]
            },
            {
                "id": 1045,
                "name": "Test-03-NUEVAS-MASCULINIDADES",
                "type": "test-escena-3",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "/videos/PASCACIO/pascacio-E3-op2.jpg",
                "choices": 1,
                "next": 46,
                "previous": 42,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 46,
                "name": "pascacio-E4",
                "type": "video-E4-pascacio",
                "video-yt": "NAhHqH57FzY",
                "src": "/videos/PASCACIO/pascacio-E4.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E4.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E4.vtt",
                "poster": "/videos/PASCACIO/pascacio-E4.jpg",
                "loop": false,
                "zones": [],
                "choices": 1,
                "next": 47,
                "interactive": false,
                "removed": false,
                "videos": [],
                "checkpoint": true,
                "duration": [82.05932, 40.054422],
                "order": 1,
                "tags": [
                    {
                        "id": 1,
                        "in": "39",
                        "out": "45",
                        "x": 70,
                        "y": 50,
                        "link": "NvAHbOr2kwE",
                        "type": "video",
                    }
                ]
            },
            {
                "id": 47,
                "name": "pascacio-E4-decision",
                "type": "choice-escena-4",
                "video-yt": "7uNVFIBrSfk",
                "src": "/videos/PASCACIO/pascacio-E4-decision.mp4",
                "src_low": "/videos/low/PASCACIO/pascacio-E4-decision.mp4",
                "vtt": null,
                "poster": "/videos/PASCACIO/pascacio-E4-decision-1.jpg",
                "choices": {
                    left: 48,
                    right: 49,
                },
                "next": 48,
                "interactive": true,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },
            {
                "id": 48,
                "name": "pascacio-E4-op1",
                "type": "video-story",
                "video-yt": "joNNUi476Tw",
                "src": "/videos/PASCACIO/pascacio-E4-op1.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E4-op1.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E4-op1.vtt",
                "poster": "/videos/PASCACIO/pascacio-E4-op1.jpg",
                "loop": false,
                "choices": 1,
                "next": 1050,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": []
            },
            {
                "id": 49,
                "name": "pascacio-E4-op2",
                "type": "video-story",
                "video-yt": "JLUGkaJ-9uk",
                "src": "/videos/PASCACIO/pascacio-E4-op2.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E4-op2.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E4-op2.vtt",
                "poster": "/videos/PASCACIO/pascacio-E4-op2.jpg",
                "loop": false,
                "choices": 1,
                "next": 51,
                "zones": [],
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "tags": [
                    {
                        "id": 1,
                        "in": "33",
                        "out": "39",
                        "x": 70,
                        "y": 50,
                        "link": "/assets/img/comics/5-limites-y-autocuidado.jpg",
                        "link_en": "/assets/img/comics/en-5-limits-and-self-care.jpg",
                        "type": "image",
                    },
                    {
                        "id": 2,
                        "in": "55",
                        "out": "61",
                        "x": 70,
                        "y": 50,
                        "link": "/assets/img/comics/6-educacion-sexual.jpg",
                        "link_en": "/assets/img/comics/en-6-sexual-education-and-prevention.jpg",
                        "type": "image",
                    }
                ]
            },
            {

                "id": 1050,
                "name": "Test-05-LIMITES-Y-AUTOCUIDADO-PARA-PASCACIO",
                "type": "test-escena-5",
                "video-yt": "",
                "src": null,
                "src_low": null,
                "vtt": null,
                "poster": "",
                "choices": 1,
                "next": 51,
                "previous": 47,
                "interactive": false,
                "removed": false,
                "videos": [],
                "order": 1,
                "zones": [],
                "tags": []
            },
            {
                "id": 51,
                "name": "pascacio-E5",
                "type": "video-E5-pascacio",
                "video-yt": "W9sgste5fDY",
                "src": "/videos/PASCACIO/pascacio-E5.mp4",
                "src_low": "/videos/PASCACIO/pascacio-E5.mp4",
                "vtt": "/videos/PASCACIO/pascacio-E5.vtt",
                "poster": "/videos/PASCACIO/pascacio-E5.jpg",
                "loop": false,
                "zones": [],
                "choices": 1,
                "next": 290,
                "interactive": false,
                "removed": false,
                "videos": [],
                "checkpoint": true,
                "duration": [131.657143],
                "order": 1,
                "tags": [
                    {
                        "id": 1,
                        "in": "33",
                        "out": "39",
                        "x": 70,
                        "y": 50,
                        "link": "RXPyN7aciFI",
                        "type": "video",
                    },
                    {
                        "id": 1,
                        "in": "55",
                        "out": "61",
                        "x": 70,
                        "y": 50,
                        "link": "/assets/img/comics/6-educacion-sexual.jpg",
                        "link_en": "/assets/img/comics/en-6-sexual-education-and-prevention.jpg",
                        "type": "image",
                    }
                ]
            }
        ],
        "lang": false,
        "options": {},
        "showForms": 0,
        "userdata": {},
        "video": [],
        "checkpoint":false,
        "character": null,
    }
;

let persistentState = {}
if (Object.keys(persistentState).length > 0) {
    initialState = persistentState;
}

export default initialState
