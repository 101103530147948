import React, {useState} from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {getCheckpoint} from "../Forms/formActions";




const Timeline = ({items})=>{

    const  [userCheckpoint,setUserCheckpoint] = useState(null);
    const {checkpoints,options,device} = useSelector(
        state => ({
            checkpoints: state.get("items").filter(i=> i.checkpoint  === true),
            options: state.get("options"),
            device: state.get("device")
        }),
        shallowEqual
    );

    useState(()=>{
        getCheckpoint().then((res)=>{
            // console.log("RES", res)
            setUserCheckpoint(res);

        });
    })
    // console.log(items)
    return(
        <div className={'timeline-in'}>
            <ul>
                {
                    checkpoints.filter(i => i.name.substr(0, 5) === 'petra').map((i,index)=>{

                        return <li key={'timeline'+ index } className={ index <= 1 ? 'active' : ''}>

                        <span>{ (items.length && items[0].id === i.id) || (items.length && items[0].choices === i.id)  ? 'A' : index}</span></li>
                    })
                }
                <li>
                    <span>ok</span>
                </li>
            </ul>
            <div className={'line'}></div>
        </div>


    )
};

export default Timeline
