import React from 'react';
import {useRouter, navigate, useRoutes} from 'hookrouter';
import routes from '../../../routes';
import Items from "../../Items";
import * as types from "../../../actions/actionTypes";
import Forms from "../../Forms/Form";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Lang from '../../Lang';

const Home = () => {
    const dispatch = useDispatch();
    const {showForms} = useSelector(state => ({
        showForms: state.get('showForms'),
    }), shallowEqual);
    return (
        <div className="App">
            <Lang />
            <Items/>

            <div onClick={()=>{
                dispatch({type:types.SHOW_FORMS, payload:1})
            }}> show</div>
            {showForms !== 0 ? <Forms form={showForms} />: <></>}


        </div>
    )
}


export default Home
